import { useEffect, useRef } from 'react'
import { OSU_NAV_ID } from '../../util/constants'

const useCssVariableUpdater = () => {
    const navHeight = useRef()
    const navbarRect = document.getElementById(OSU_NAV_ID)?.getBoundingClientRect() || {}
    const headerRect = document.getElementById('grad-fee-header')?.getBoundingClientRect() || {}
    const outOfView = navbarRect.y && navbarRect.y <= 0

    useEffect(() => {
        const root = document.documentElement

        if (!outOfView) {
            navHeight.current = navbarRect.height + headerRect.height
            root.style.setProperty('--nav-height', (navbarRect.height + headerRect.height) + 'px')
        } else {
            navHeight.current = headerRect.height
            root.style.setProperty('--nav-height', headerRect.height + 'px')
        }
    }, [navbarRect.height, headerRect.height, outOfView])

    return {
        navHeight: navHeight.current
    }
}

export default useCssVariableUpdater