import { GET_STUDENT_AWARD_HISTORY, GET_STUDENT_AWARD_SEMESTER_DETAIL} from "../actions-index";

export function studentHistory(state = {}, action = {}) {
    const { payload = {} } = action
    const { status, awardId, semester, emplid, history = {}, semesterDetail = {} } = payload || {}
    let newHistoryState = Object.assign({}, state)
    let validatedHistory = (awardId === history?.award?.id) && (emplid === history?.emplid)
    if(status) {
        newHistoryState.status = status
    } else if (awardId || emplid) {
        newHistoryState.status = validatedHistory ? 'success' : 'ERROR'
        newHistoryState = Object.assign(newHistoryState, history)
    }
    
    switch(action.type) {
        case GET_STUDENT_AWARD_HISTORY:
            return newHistoryState
        case GET_STUDENT_AWARD_SEMESTER_DETAIL:
            return {
                ...state,
                semesterDetail: {
                    awardId, 
                    semester, 
                    emplid,
                    ...semesterDetail,
                    status: status ? status : Object.keys(semesterDetail).length > 0 ? 'success' : 'ERROR'
                }
            }
        default:
            return state
    }
}