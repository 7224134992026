import React, { useContext, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../../App/context";

const Unauthorized = (props = {}) => {
  const { redirectPath } = props
  const { authStatus } = useContext(AppContext);
  const location = useLocation()

  if(!authStatus || authStatus === 'cleared') {
    return <Redirect to={{ pathname: '/login', state: { redirectPath: location?.state?.redirectPath || redirectPath } }} />
  } 
  
  if (authStatus === "loading") {
    return <div>Logging in</div>;
  }

  return (
    <span >
      You are not authorized to view this application. If you believe this to be in error, 
      contact the{" "}<a href="mailto:oasis-support@lists.osu.edu">Web and Mobile Team</a>{" "}for assistance.
    </span>
  );
}

export default Unauthorized;
