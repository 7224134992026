import { API } from "aws-amplify";
import { generateAction } from "../redux-helpers";
import { GET_DASHBOARD, setStudentSemesterTables, setActiveStudents } from "../actions-index";
import * as queries from "../graphql/queries";
import { setAvailableSemesters, setStudentsWithoutFunding } from "../StudentSemesterTables/actions";

export function getDashboard() {
  return async (dispatch) => {
    dispatch(generateAction(GET_DASHBOARD, { status: "loading" }));
    dispatch(setStudentSemesterTables({ status: "loading" }))

    try {
      var response = await API.graphql({
        query: queries.getDashboard
      });
      const dashboardAwards = response?.data?.getDashboard?.requestedAwards
      const upcomingDeadline = response?.data?.getDashboard?.businessProcess?.upcomingDeadline
      const fundingRemaining = response?.data?.getDashboard?.fundingRemaining
      const activeStudents = response?.data?.getDashboard?.activeStudents || []
      const studentsWithoutFunding = response?.data?.getDashboard?.studentsWithoutFunding || []
      if(!response?.data?.getDashboard || Object.keys(response?.data?.getDashboard).length === 0) {
        throw new Error('Unable to get dashboard')
      }
      let counts = { studentsWithoutFunding: studentsWithoutFunding?.length, activeStudents: activeStudents?.length }
      dispatch(setAvailableSemesters({ list: response?.data?.getDashboard?.businessProcess?.availableSemesters }))
      dispatch(setActiveStudents({ list: activeStudents,  }))
      dispatch(setStudentsWithoutFunding({ list: studentsWithoutFunding }))
      dispatch(generateAction(GET_DASHBOARD, { 
        dashboardAwards,
        upcomingDeadline,
        fundingRemaining,
        studentCount: counts
      }));
    } catch (err) {
      console.log("graphql error: ", err);
      dispatch(generateAction(GET_DASHBOARD, { status: 'ERROR' }))
      dispatch(setStudentSemesterTables({ status: "ERROR" }))
    }
  };
}