import {GET_ACAD_PROGRAMS, CREATE_AWARD, CLEAR_AWARD} from "../actions-index";

export function awardRequest(state = {}, action = {}) {
    switch(action.type) {
        case GET_ACAD_PROGRAMS:
            return {
                ...state,
                programs: {
                    status: 'success',
                    ...action.payload
                }
            }
        case CREATE_AWARD:
            return {
                ...state,
                awardResult: {
                    status: 'success',
                    ...action.payload
                }
            }
        case CLEAR_AWARD:
            if(action?.payload?.clear) {
                return {
                    ...state,
                    awardResult: {}
                }
            }
            return state
        default:
            return state
    }
}