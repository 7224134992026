import {GET_WORKFLOW_DETAIL} from "../actions-index";

export function workflowDetail(state = {}, action = {}) {
    switch(action.type) {
        case GET_WORKFLOW_DETAIL:
            return {
                ...state,
                workflows: {
                    status: 'success',
                    ...action.payload
                }
            }
        default:
            return state
    }
}