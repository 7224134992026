import { Mui, TOC } from "@osu/react-ui";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import "../Footer.scss";

const FooterLink = Mui.withStyles((theme) => ({
    root: {
        color: '#9db8db',
        textDecoration: 'underline',
        '&:hover': {
            color: '#6da2e7',
        },
    },
}))(Mui.Link);

const useStyles = Mui.makeStyles((theme) => {
  return {
    footer: {
        position: 'relative',
        zIndex: theme?.zIndex?.drawer
    }
  }
})


const Footer = () => {
  const scrollTimeout = useRef();
  const footerRef = useRef();
  const adjustFooterAt = useRef();
  const tocContext = useContext(TOC.Context)
  const toc = document.getElementById('table-of-contents')
  const tocList = toc?.querySelector('UL')
  const boundingClientRect =  tocList && tocList.getBoundingClientRect()
  const tocListItems = tocList?.querySelectorAll('LI')
  const adjustToc = tocContext?.paths?.length > 0 
    && (tocContext?.paths?.length === tocListItems?.length)
    const footerHeight = footerRef?.current?.clientHeight

  useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--footer-height', footerHeight + 'px')
  }, [footerHeight])

  useEffect(() => {
    const hasStatic = toc?.classList?.contains('toc-static')
  
    if(!hasStatic && adjustToc && boundingClientRect.height) {
        const newHeight = (boundingClientRect.height + boundingClientRect.top)
        if(adjustFooterAt.current !== newHeight) {
          adjustFooterAt.current = newHeight
        }
    }
  }, [adjustToc, boundingClientRect, toc?.classList])


  const handleScroll = useCallback(() => {
    const element = footerRef?.current
    
    if (!element || !adjustFooterAt.current) { 
      return null;
    }

    clearTimeout(scrollTimeout.current);

    scrollTimeout.current = setTimeout(() => {
      const footerDimensions = element.getBoundingClientRect()
      const hasStatic = toc?.classList?.contains('toc-static')
      const hasFixed = toc?.classList?.contains('toc-fixed')

      const overlap = (adjustFooterAt.current) > footerDimensions?.top

      if(toc && !overlap && !hasFixed) {
        hasStatic && toc.classList.remove('toc-static')
        toc.classList.add('toc-fixed')
      } else if(toc && overlap && !hasStatic) {
        hasFixed && toc.classList.remove('toc-fixed')
        toc.classList.add('toc-static')
      }      
      
    }, 50);
  }, [toc]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  
  const classes = useStyles()

  return (
    <footer ref={footerRef} className={classes.footer}>
      <Mui.Container>
        <a
          aria-label="The Ohio State University home page"
          href="https://www.osu.edu/"
        >
          <img
            className="image-logo"
            alt=""
            src={require("../assets/osu-long-logo.png").default}
          />
        </a>
        <section className="margin-x-3 margin-y-2">
          <p>© 2021 - The Ohio State University Graduate School</p>
          <p>
            250 University Hall | 230 N Oval Mall | Columbus OH 43210
            <span className="display-block">
              Fellowship Services Email:{" "}
              <FooterLink href="mailto:grad-schoolfellowships@osu.edu">
                grad-schoolfellowships@osu.edu
              </FooterLink>
            </span>
          </p>
        </section>
        <div variant="body2" className="margin-x-3 margin-y-2">
          If you have accessibility needs using the Graduate School Fellowship
          and Awards system, please email{" "}
          <FooterLink href="mailto:grad-schoolaccessibility@osu.edu">
            grad-schoolaccessibility@osu.edu
          </FooterLink>{" "}
          for support.
        </div>
      </Mui.Container>
    </footer>
  );
};

export default Footer;
