import { STUDENT_SEMESTER_TABLES, ACTIVE_STUDENTS, AVAILABLE_SEMESTERS, STUDENTS_WITHOUT_FUNDING } from "../actions-index";

export function studentSemesterTables(state = {}, action = {}) {
  const { payload } = action;
  let semesterTables = Object.assign({ semesters: {}, studentsWithoutFunding: {}, activeStudents: {} }, state);
  semesterTables.status = payload?.status;

  switch (action.type) {
    case STUDENT_SEMESTER_TABLES:
        return semesterTables
    case AVAILABLE_SEMESTERS:
      if (payload?.list) {
        semesterTables.semesters.list = payload?.list;
        semesterTables.status = "success";
      } else if (!semesterTables.status) {
        semesterTables.status = "ERROR";
      }
      return semesterTables;
    case STUDENTS_WITHOUT_FUNDING:
        if (payload?.list) {
          semesterTables.studentsWithoutFunding.list = payload?.list;
          semesterTables.status = "success";
        } else if (!semesterTables.status) {
          semesterTables.status = "ERROR";
        }
        return semesterTables;
    case ACTIVE_STUDENTS:
        if (payload?.list) {
          semesterTables.activeStudents.list = payload?.list;
          semesterTables.activeStudents.pending = payload?.pending || []
          semesterTables.status = "success";
        } else if (!semesterTables.status) {
            semesterTables.status = "ERROR";
        }
        return semesterTables;
    default:
      return state;
  }
}
