import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { GET_STUDENT_INFO } from "../actions-index";
import * as queries from '../graphql/queries';


export function findStudentByNameN(values = {}) {
    return async (dispatch) => {
        dispatch(generateAction(GET_STUDENT_INFO, { status: "loading" }));
        try {
            var response = await API.graphql({ query: queries.getPersonInfo, variables: { ...values } });
            dispatch(generateAction(GET_STUDENT_INFO, response.data.getPersonInfo));
        }
        catch (err) {
            console.error("Error getting student info: ", err);
            dispatch(generateAction(GET_STUDENT_INFO, { status: "error" }));
        }
    }
}