export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';

export const PAGE_HEADING = 'PAGE_HEADING';
export const PAGE_BREADCRUMB_ROUTES = 'PAGE_BREADCRUMB_ROUTES';
export const PAGE_HEADING_VARIANT = 'PAGE_HEADING_VARIANT';

//forms
export const SEARCH_PI_ADVISOR = 'SEARCH_PI_ADVISOR';
export const CREATE_AWARD = 'CREATE_AWARD';
export const CLEAR_AWARD = 'CLEAR_AWARD';
export const GET_AVAILABLE_SEMESTERS = 'GET_AVAILABLE_SEMESTERS';
export const UPDATE_AWARD = 'UPDATE_AWARD';
export const CACHED_UPDATE_AWARD = 'CACHED_UPDATE_AWARD';
export const CACHED_SUBMIT_AWARD = 'CACHED_SUBMIT_AWARD';
export const SUBMIT_AWARD_ERRORS = 'SUBMIT_AWARD_ERRORS';
export const CACHED_CANCEL_WORKFLOW = 'CACHED_CANCEL_WORKFLOW';
export const GET_AWARD = 'GET_AWARD';
export const GET_SIGNED_URL = 'GET_SIGNED_URL';
export const PUT_FILES_S3 = 'PUT_FILES_S3';
export const FILE_PROGRESS = 'FILE_PROGRESS';
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SUBMIT_AWARD = 'SUBMIT_AWARD';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const CACHED_COMPLETE_TASK = 'CACHED_COMPLETE_TASK';
export const CANCEL_WORKFLOW = 'CANCEL_WORKFLOW';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const GET_FILE = 'GET_FILE';
export const ADD_COMMENT = 'ADD_COMMENT';

//AddStudent
export const GET_STUDENT_AWARD_INFO = 'GET_STUDENT_AWARD_INFO';
export const SET_TEMP_STUDENT_AWARD_INFO  = 'SET_TEMP_STUDENT_AWARD_INFO';
export const SUBMIT_STUDENT = 'SUBMIT_STUDENT';
export const CACHED_SUBMIT_STUDENT = 'CACHED_SUBMIT_STUDENT';

//funding verification
export const SUBMIT_FUNDING_VERIFICATION = 'SUBMIT_FUNDING_VERIFICATION';
export const CACHED_SUBMIT_FUNDING_VERIFICATION = 'CACHED_SUBMIT_FUNDING_VERIFICATION';

//workflow detail
export const GET_WORKFLOW_DETAIL = 'GET_WORKFLOW_DETAIL';

//award request
export const GET_ACAD_PROGRAMS = 'GET_ACAD_PROGRAMS';
export const GET_ACTIVE_REQUESTS = 'GET_ACTIVE_REQUESTS';
export const GET_ALL_AWARD_REQUESTS = 'GET_ALL_AWARD_REQUESTS';
export const CREATE_TEMP_AWARD_REQUESTS = 'CREATE_TEMP_AWARD_REQUESTS';
export const CLEAR_TEMP_AWARD_REQUESTS = 'CLEAR_TEMP_AWARD_REQUESTS';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_PENDING = 'GET_PENDING';
export const STUDENTS_WITHOUT_FUNDING = 'STUDENTS_WITHOUT_FUNDING';
export const ACTIVE_STUDENTS = 'ACTIVE_STUDENTS';
export const PENDING_COUNT = 'PENDING_COUNT';
export const AVAILABLE_SEMESTERS = 'AVAILABLE_SEMESTERS';
export const STUDENT_SEMESTER_TABLES = 'STUDENT_SEMESTER_TABLES';

//dashboard
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';

//findstudent
export const GET_STUDENT_INFO = 'GET_STUDENT_INFO';
export const GET_STUDENT_AWARD_HISTORY = 'GET_STUDENT_AWARD_HISTORY';
export const GET_STUDENT_AWARD_SEMESTER_DETAIL = 'GET_STUDENT_AWARD_SEMESTER_DETAIL';

/* Export each action file below */
export * from './Authentication/actions';
export * from './Forms/actions';
export * from './Authentication/actions';
export * from './AwardRequest/actions';
export * from './WorkflowDetail/actions';
export * from './ViewRequests/actions';
export * from './MainDashboard/actions';
export * from './PendingTasks/actions';
export * from './StudentSemesterTables/actions';
export * from './FundingVerification/actions';
export * from './AddStudent/actions';
export * from './FindStudent/actions';
export * from './StudentHistory/actions';
export * from './StudentsOnAward/actions';