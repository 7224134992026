/* eslint-disable */

import { activeStudents, awardFields, metrics, studentFunding } from "./templates";


export const getAward = `query GetAward($id: String!) {
  getAward(id: $id) {
    id
    status
    decisionDate
    documentation {
      directive
      documentId
      fileName
    }
    availableSemesters {
      label
      semester
    }
    contactInformation {
      approvers {
        email
        emplid
        id
        name
      }
      requester {
        email
        emplid
        id
        name
      }
      principalInvestigator {
        email
        emplid
        id
        name
      }
      gsc {
        email
        emplid
        id
        name
      }
    }
    fundingInformation {
      awardId
      grantId
      reason
      fundingType
    }
    comments {
      comment
      date
      emplid
      name
    }
    category
    college
    name
    program
    legacyId
    sponsorFunding {
      fundingAgency
      monthlyStipend
      deadline
    }
    studentFunding {
      numberOfStudents
      fundingPercent
      total
      available
      semesters {
        label
        semester
      }
    }
    workflow {
      awardRequest {
        currentApprovers {
          name
          emplid
          email
          campusId
          actionDate
          action
        }
        history {
          name
          emplid
          email
          campusId
          actionDate
          action
        }
        status
        startDate
        reason
        id
        currentState
      }
      fundingVerification {
        history {
          emplid
          campusId
          email
          name
          actionDate
          action
        }
        currentApprovers {
          emplid
          campusId
          email
          name
          actionDate
          action
        }
        id
        reason
        status
        startDate
        currentState
      }      
    }
    students {
      semesters {
        academicYear
        label
        semester
        status
      }
      campusId
      emplid
      name
    }
  }
}
`;

export const getWorkflowByAward = `query GetAward($id: String!) {
  getAward(id: $id) {
    id
    status
    workflow {
      awardRequest {
        currentApprovers {
          name
          emplid
          email
          campusId
          actionDate
          action
        }
        history {
          name
          emplid
          email
          campusId
          actionDate
          action
        }
        status
        startDate
        reason
        id
        currentState
      }
      fundingVerification {
        history {
          emplid
          campusId
          email
          name
          actionDate
          action
        }
        currentApprovers {
          emplid
          campusId
          email
          name
          actionDate
          action
        }
        id
        reason
        status
        startDate
        currentState
      }
    }
  }
}
`;

export const getAvailableSemesters = `query GetAvailableSemesters($numberOfYears: Int) {
  getAvailableSemesters(numberOfYears: $numberOfYears) {
    academicYear
    semesters {      
      semester
      label
    }
  }
}
`;
export const getAcademicPrograms = `query GetAcademicPrograms($program: String) {
  getAcademicPrograms(program: $program) {
    program
    college
    plans {
      plan
      description
    }
  }
}
`;

export const getSignedUrl = `query GetSignedUrl($id: String) {
  getSignedUrl(id: $id) {
    signedUrl
    s3Object
  }
}`

export const getFile = `query GetFile($documentId: String!, $fileName: String!) {
  getFile(documentId: $documentId, fileName: $fileName) {
    signedUrl
  }
}`

export const getStudentAwardDetails = `query GetStudentAwardDetails($awardId: String!, $includeMetrics: Boolean!, $studentId: String!) {
  getStudentAwardDetails(awardId: $awardId, includeMetrics: $includeMetrics, studentId: $studentId) {
    award {
      semesters {
        academicYear
        label
        semester
        status
      }
      college
      id
      name
      program
    }
    campusId
    name
    emplid
    ${metrics}
  }
}`


export const getAwards = `query GetAwards($status: String!) {
  getAwards(status: $status) ${awardFields}
}`

export const getActiveAwards = `query GetDashboard {
  getDashboard {
    fundingRemaining ${awardFields}
  }
}`

export const getDashboard = `query GetDashboard {
  getDashboard {
    businessProcess {
      availableSemesters {
        semester
        label
      }
      upcomingDeadline
    }
    requestedAwards {
      SAVED ${awardFields}
      PREAPPROVED ${awardFields}
      PENDING_FUNDING_INFORMATION ${awardFields}
      PENDING ${awardFields}
      EXPIRED ${awardFields}
      CANCELLED ${awardFields}
      APPROVED ${awardFields}
      DENIED ${awardFields}
      DENIED_FUNDING_INFORMATION ${awardFields}
    }
    fundingRemaining ${awardFields}
    activeStudents ${activeStudents}
    studentsWithoutFunding ${studentFunding}
  }
}`

export const getStudentsWithSemesters = `query GetActiveStudentsWithSemesters {
  getDashboard {
    businessProcess {
      availableSemesters {
        semester
        label
      }
    }
    activeStudents ${activeStudents}
    studentsWithoutFunding ${studentFunding}
  }
}`

export const getPendingTasks = `query GetPendingTasks {
  getPendingTasks {
    name
    fundingAgency
    principleInvestigator
    deadline
    decisionDate
    status
    program
    college
    id
  }
}`

export const getStudentSemesterAwardDetail = `query GetStudentSemesterAwardDetail($awardId: String!, $includeMetrics: Boolean!, $semester: String!, $studentId: String!) {
  getStudentSemesterAwardDetail(awardId: $awardId, includeMetrics: $includeMetrics, semester: $semester, studentId: $studentId) {
    award {
      academicYear
      appointmentType
      comments {
        comment
        date
        name
        emplid
      }
      workflows {
        history {
          action
          actionDate
          name
          email
          emplid
        }
        currentApprovers {
          action
          actionDate
          name
          email
          emplid
        }
        id
        currentState
        reason
        status
      }
      semester
      label
    }
    name
    emplid
    campusId
    ${metrics}
  }
}`

export const getPersonInfo = `query GetPersonInfo($includeAwards: Boolean!, $includeMetrics: Boolean!, $studentId: String!) {
  getPersonInfo(includeAwards: $includeAwards, includeMetrics: $includeMetrics, studentId: $studentId) {
    associatedAwards {
      program
      name
      id
      college
    }
    metrics {
      standing
      plans
      earnedUnits
      cumGpa
    }
    affiliations
    name
    campusId
    emplid
    email
  }
}`