import { SEARCH_PI_ADVISOR, UPDATE_AWARD, CANCEL_WORKFLOW, ADD_COMMENT, CLEAR_FORM_DATA, GET_FILE, GET_AVAILABLE_SEMESTERS, GET_AWARD, GET_SIGNED_URL, PUT_FILES_S3, FILE_PROGRESS, UPLOAD_FILE, SUBMIT_AWARD, COMPLETE_TASK, DELETE_REQUEST, CACHED_SUBMIT_AWARD, CACHED_UPDATE_AWARD, CACHED_CANCEL_WORKFLOW, CACHED_COMPLETE_TASK, SUBMIT_AWARD_ERRORS } from "../actions-index";

const prepareOptions = (stateOptions = [], newOptions = []) => {
    let options = []
    if(Array.isArray(stateOptions) && Array.isArray(newOptions)) {
        options = stateOptions.concat(newOptions)
    } else if (!Array.isArray(newOptions)) {
        options = stateOptions
    } else {
        options = newOptions
    }

    let filtered = []
    options.forEach(opt => {
        const alreadyInFiltered = filtered.some(filteredOption => filteredOption.emplid === opt.emplid)
        if(!alreadyInFiltered) {
            filtered.push(opt)
        }
    })

    return filtered
}

export function forms(state = {}, action = {}) {
    const { payload = {} } = action
    const { clear, data } = payload || {}

    const successful = action?.payload?.id && !action?.payload?.status

    switch (action.type) {
        case SEARCH_PI_ADVISOR:
            return {
              ...state,
              advisors: {
                status: payload?.status || "success",
                data: prepareOptions(state?.advisors?.data, data)
              },
            };
        case UPDATE_AWARD:
            return {
                ...state,
                ...successful ? {
                    TEMP: {
                        ...state.TEMP || {},
                        cachedUpdate: null
                    }
                }: {},
                awardResult: {
                    status: 'success',
                    ...action.payload
                }
            }
        case SUBMIT_AWARD:
            return {
                ...state,
                ...successful ? {
                    TEMP: {
                        ...state.TEMP || {},
                        cachedSubmit: null,
                        errors: []
                    }
                }: {},
                submitResult: {
                    status: successful ? 'success' : (payload?.status || 'error'),
                    ...action.payload
                }
            }
        case COMPLETE_TASK:
            return {
                ...state,
                completeTaskResult: {
                    status: action?.payload?.success ?  'success' : (action?.payload?.status || 'error'),
                    ...action.payload
                },
                ...action?.payload?.success ? {
                    TEMP: {
                        ...state.TEMP || {},
                        cachedCompleteTask: null
                    }
                }: {},
            }
        case GET_AVAILABLE_SEMESTERS:
            return {
                ...state,
                semesters: {
                    status: Array.isArray(action.payload) ? 'success' : (action?.payload?.status || 'error'),
                    items: Array.isArray(action.payload) ? action.payload : []
                }
            }
        case GET_AWARD:
            return {
                ...state,
                award: {
                    status: 'success',
                    ...action.payload
                }
            }
        case ADD_COMMENT:
            return {
                ...state,
                commentStatus: {
                    status: 'success',
                    ...action.payload
                }
            }
        case GET_FILE:
            return {
                ...state,
                fileData: {
                    status: 'success',
                    ...action.payload
                }
            }
        case GET_SIGNED_URL:
            return {
                ...state,
                signedUrl: {
                    status: 'success',
                    url: action.payload
                }
            }
        case PUT_FILES_S3:
            return {
                ...state,
                uploadFiles: {
                    status: 'success',
                    ...action.payload
                }
            }
        case FILE_PROGRESS:
            return {
                ...state,
                fileProgress: action.payload
            }
        case UPLOAD_FILE:
            return {
                ...state,
                uploadStatus: {
                    status: 'success',
                    ...action.payload
                }
            }
        case DELETE_REQUEST:
            return {
                ...state,
                deleteStatus: {
                    status: 'success',
                    ...action.payload
                }
            }
        case CANCEL_WORKFLOW:
            return {
                ...state,
                cancelWorkflowStatus: {
                    ...action.payload || {},
                    status: action?.payload?.success ?  'success' : (action?.payload?.status || 'error'),
                },
                ...action?.payload?.success ? {
                    TEMP: {
                        ...state.TEMP || {},
                        cachedWorkflow: null
                    }
                }: {},
            }
        case CLEAR_FORM_DATA:
            return {
                ...state,
                cancelWorkflowStatus: {},
                TEMP: clear ? {} : state.TEMP,
                deleteStatus: {
                    status: 'completed',
                    ...action.payload
                }
            }
        case CACHED_SUBMIT_AWARD:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    cachedSubmit: action.payload
                }
            }
        case CACHED_UPDATE_AWARD:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    cachedUpdate: action.payload
                }
            }
        case CACHED_CANCEL_WORKFLOW:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    cachedWorkflow: action.payload
                }
            }
        case CACHED_COMPLETE_TASK:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    cachedCompleteTask: action.payload
                }
            }
        case SUBMIT_AWARD_ERRORS:
            return {
                ...state,
                TEMP: {
                    errors: action?.payload?.errors || []
                }
            }
        default:
            return state
    }
}