import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setUser, authSignOut } from '../../actions-index';
import AuthButton from "../components/AuthButton";

const mapStateToProps = (state, ownProps) => {
    const { authentication } = state
    const { status = '' } = authentication

    return {
        status,
        name: authentication.user?.name
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (forceRedirect) => dispatch(setUser(forceRedirect)),
        signOut: () => dispatch(authSignOut()),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthButton));
