import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { SUBMIT_FUNDING_VERIFICATION, getAward, CACHED_SUBMIT_FUNDING_VERIFICATION } from "../actions-index";
import * as mutations from '../graphql/mutations';

export function submitFundingVerification(values = {}) {
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_FUNDING_VERIFICATION, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.submitFundingVerification, variables: { ...values } });
            if(response?.data?.submitFundingVerification?.id) {
                dispatch(generateAction(SUBMIT_FUNDING_VERIFICATION, response.data.submitFundingVerification));
                dispatch(getAward(response.data.submitFundingVerification.id))
            }

        }
        catch (err) {
            console.error("Error submitting funding verification: ", err);
            dispatch(generateAction(SUBMIT_FUNDING_VERIFICATION, { status: "error" }));
            dispatch(generateAction(CACHED_SUBMIT_FUNDING_VERIFICATION, values));
        }
    }
}

export function clearCache() {
    return (dispatch) => {
        dispatch(generateAction(CACHED_SUBMIT_FUNDING_VERIFICATION, null));
    }
}
