import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { GET_STUDENT_AWARD_INFO, SET_TEMP_STUDENT_AWARD_INFO, SUBMIT_STUDENT, CACHED_SUBMIT_STUDENT, getStudentSemesterDetail } from "../actions-index";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getMetricsFromState } from '../util/functions';


export function getStudentAwardDetail(nameN, awardId) {
    return async (dispatch, getState) => {
        dispatch(generateAction(GET_STUDENT_AWARD_INFO, { status: "loading" }));
        const state = Object.assign({ addStudent: { TEMP: {} }  }, getState())
        const metrics = getMetricsFromState(state, nameN)
        const includeMetrics = !metrics
    
        try {
            var response = await API.graphql({ query: queries.getStudentAwardDetails, variables: { awardId: awardId, includeMetrics, studentId: nameN } });
            let awardInfo = Object.assign({}, response.data.getStudentAwardDetails)
            if(!includeMetrics) {
                awardInfo.metrics = metrics
            } else {
                dispatch(setTempMetrics({ 
                    emplid: awardInfo?.emplid, 
                    campusId: awardInfo?.campusId, 
                    metrics: awardInfo?.metrics || {}
                }))
            }

            dispatch(generateAction(GET_STUDENT_AWARD_INFO, awardInfo));
        }
        catch (err) {
            console.error("getStudentAwardDetailError getting student info: ", err);
            dispatch(generateAction(GET_STUDENT_AWARD_INFO, { status: "error" }));
        }
    }
}

export function setTempMetrics({ emplid, campusId, metrics }) {
    return dispatch => {
      dispatch(generateAction(SET_TEMP_STUDENT_AWARD_INFO, { emplid, campusId, metrics }));
    }
  }

export function submitStudentAward(values = {}) {
    const refreshData = values?.refresh?.awardId && values?.refresh?.semester && values?.refresh?.emplid
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_STUDENT, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.submitStudentAward, variables: { ...values } });
            dispatch(generateAction(SUBMIT_STUDENT, response.data.submitStudentAward));
            const validResponse = response?.data?.submitStudentAward?.[0]?.workflowId
            if(refreshData && validResponse) {
                const { awardId, semester, emplid } = values?.refresh
                dispatch(getStudentSemesterDetail(awardId, semester, emplid))
            }
        }
        catch (err) {
            console.error("Error submitting student: ", err);
            dispatch(generateAction(CACHED_SUBMIT_STUDENT, values));
            dispatch(generateAction(SUBMIT_STUDENT, { status: "error", message: err?.errors[0]?.message}));
        }
    }
}