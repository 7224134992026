import { GET_STUDENT_INFO } from "../actions-index";

export function findStudent(state = {}, action = {}) {
    switch (action.type) {
        case GET_STUDENT_INFO:
            return {
                ...state,
                studentInfo: {
                    status: 'success',
                    ...action.payload
                }
            }
        default:
            return state
    }
}