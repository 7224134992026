import { API } from "aws-amplify";
import { generateAction } from "../redux-helpers";
import { GET_ALL_AWARD_REQUESTS, GET_ACTIVE_REQUESTS, getDashboard, CLEAR_TEMP_AWARD_REQUESTS, CREATE_TEMP_AWARD_REQUESTS } from "../actions-index";
import * as queries from "../graphql/queries";
import { formatAwardsRequests } from "./transform";

export function getActiveAwards() {
  return async (dispatch, getState) => {
    dispatch(generateAction(GET_ACTIVE_REQUESTS, { status: "loading" }));
    const { dashboard = {} } = getState() || {};
    if(Array.isArray(dashboard?.fundingRemaining) && dashboard?.fundingRemaining.length > 0) {
      return dispatch(generateAction(GET_ACTIVE_REQUESTS, { list: formatAwardsRequests(dashboard.fundingRemaining) }));
    } else {
      try {
        var response = await API.graphql({
          query: queries.getActiveAwards
        });
        if(Array.isArray(response?.data?.getDashboard?.fundingRemaining)) {
          const formattedList = formatAwardsRequests(response?.data?.getDashboard?.fundingRemaining)
          dispatch(generateAction(GET_ACTIVE_REQUESTS, { list: formattedList }));
        } else {
          throw new Error('Unexpected response for format award requests')
        }
      } catch (error) {
        dispatch(generateAction(GET_ACTIVE_REQUESTS, { status: "error" }));
      }
    }
  }
}

export function getAwardRequests(status, filterByDash) {
  if (filterByDash) {
    return async (dispatch, getState) => {
      let filteredList = [];
      dispatch(generateAction(GET_ALL_AWARD_REQUESTS, { status: "loading" }));
      const { dashboard = {} } = getState() || {};
      const dashAwards = dashboard?.dashboardAwards || {};
      try {
        if (Object.keys(dashboard).length === 0) {
          await dispatch(getDashboard());
          const { dashboard: secondDash = {} } = getState() || {};
          if (
            secondDash?.dashboardAwards &&
            secondDash.dashboardAwards[status]
          ) {
            filteredList = secondDash.dashboardAwards[status];
          }
        } else if (dashAwards && dashAwards[status]) {
          filteredList = dashAwards[status];
        }
      } catch (error) {
        console.error("Error", error);
      }

      dispatch(
        generateAction(GET_ALL_AWARD_REQUESTS, {
          typeOfList: status,
          list: Array.isArray(filteredList)
            ? formatAwardsRequests(filteredList)
            : [],
        })
      );
    };
  }
  return async (dispatch, getState) => {
    dispatch(generateAction(GET_ALL_AWARD_REQUESTS, { status: "loading" }));
    const existingData = getState() || {}
    const TEMP_stored_data = existingData?.allAwardRequests?.TEMP
    if(TEMP_stored_data && TEMP_stored_data[status] && TEMP_stored_data[status].length > 0) {
        dispatch(generateAction(GET_ALL_AWARD_REQUESTS, {
            list: TEMP_stored_data[status],
            typeOfList: status
        }))
        return
    }

    try {
      var response = await API.graphql({
        query: queries.getAwards,
        variables: { status },
      });
      if (Array.isArray(response?.data?.getAwards)) {
          const formattedAwards = formatAwardsRequests(response.data.getAwards)
        dispatch(
          generateAction(GET_ALL_AWARD_REQUESTS, {
              list: formattedAwards,
              typeOfList: status
          })
        );
        dispatch(
          generateAction(CREATE_TEMP_AWARD_REQUESTS, {
              [status]: formattedAwards
          })
        );
      } else {
        throw new Error("Unexpected response from api");
      }
    } catch (err) {
      console.log("graphql error: ", err);
      dispatch(generateAction(GET_ALL_AWARD_REQUESTS, { status: "error" }));
    }
  };
}

export function clearAwardTempDirectory() {
  return dispatch => {
    dispatch(generateAction(CLEAR_TEMP_AWARD_REQUESTS, { clear: true }));
  }
}