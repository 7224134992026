import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { FORCED_AUTHENTICATION } from "../util/constants";
import useAmplifyHub from "../Common/hooks/useAmplifyHub";
import { useIdleTimer } from "react-idle-timer";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { authenticationFlowPaths } from "../Routes/paths";
import { usePrevious } from "@osu/react-ui";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

function withAuthentication(Component) {
  function _withAuthentication(props = {}) {
    const { status, ...rest } = props
    const location = useLocation()
    const pathname = location?.pathname
    const history = useHistory()
    const publicRoute = authenticationFlowPaths.map(({ path }) => path).includes(pathname)
    const previousStatus = usePrevious(status)
    const { handleSignOut, handleSignIn } = useAmplifyHub();
    let constructedPath = !publicRoute ? `${pathname}${location?.search || ""}${location?.hash || ""}` : ""
    const storedOffPath = useRef()
    const currentPath = !storedOffPath.current ? constructedPath : storedOffPath.current
    
    useEffect(() => {
      const newSuccessState = (previousStatus !== status) && status === 'success'
      const notOnTheRightPage = publicRoute && !currentPath.startsWith(pathname)
      if(newSuccessState && notOnTheRightPage) {
        history.push(currentPath)
      }
    }, [currentPath, history, pathname, previousStatus, publicRoute, status])

    useEffect(() => {
      if(constructedPath && storedOffPath.current !== constructedPath) {
        storedOffPath.current = constructedPath
      }
    }, [constructedPath])

    const authorized = FORCED_AUTHENTICATION ? status === 'success' : true
    const isAllowedToSeeComponent = publicRoute || authorized || status === 'success'

    const handleOnIdle = (event) => {
      console.log("user is idle", event);
      console.log("last active", getLastActiveTime());
      history.push('/logout')
    };
    const { getLastActiveTime } = useIdleTimer({
      timeout: TIMEOUT,
      onIdle: handleOnIdle,
    });
    
    if(isAllowedToSeeComponent) {
      return <Component handleSignIn={handleSignIn} handleSignOut={handleSignOut} authorized={authorized} {...rest} />
    } 
    if(FORCED_AUTHENTICATION && (!status || status === 'cleared') && !publicRoute) {
      return <Redirect to={{ pathname: '/login', state: { redirectPath: currentPath } }} />
    }

    return <Redirect to={{ pathname: '/unauthorized', state: { redirectPath: currentPath } }}  />
  }

  const mapStateToProps = (state) => {
    return {
      status: state.authentication?.status,
      authentication: state.authentication,
    };
  };

  return connect(mapStateToProps, undefined)(_withAuthentication);
}

export default withAuthentication;
