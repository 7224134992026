import { SET_USER, USER_STATUS } from "../actions-index";
import { Auth } from 'aws-amplify';
import { redirectToLogIn } from '../util/login';
import { transformUser } from "./transform";

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: Object.keys(user || {}).length > 0 ? transformUser(user) : null
    }
}

export function setUserStatus(status) {
    return {
        type: USER_STATUS,
        payload: status
    }
}

export function authSignOut() {
    return function (dispatch) {
        Auth.signOut()

        dispatch(setUserStatus('error'))
        dispatch({
            type: SET_USER,
            payload: null
        })
    }
}

export function receiveAmplifyHubUser(response = {}) {
    return function (dispatch) {
        if(response?.status) {
            dispatch(setUserStatus(response?.status))
        }
        if(['error', 'cleared'].includes(response?.status)) {
            dispatch(recievedSetUser({}));
        } else if(response?.osuid || response?.displayName) {
            dispatch(recievedSetUser(response));
        } 
    }
}

// export function setUser(user = {}) {
//     dispatch(recievedSetUser(userResponse));
// }


export function setUser(forceRedirect = false) {
    return function (dispatch) {
        dispatch(setUserStatus('loading'))
        function validateSession(userResponse) {
            if (userResponse) {
                return
                // dispatch(recievedSetUser(userResponse));
            }
            return
        }

        return Auth.currentSession()
            .then((session) => {
                validateSession(session.idToken.payload)
            })
            .catch((error = {}) => {
                let { code } = error
                if (forceRedirect && (error === 'No current user' || code === "NotAuthorizedException")) {
                    redirectToLogIn();
                } else {
                    console.log('error', error)
                    setTimeout(() => {
                        Auth.currentSession()
                            .then((session) => {
                                validateSession(session.idToken.payload)
                            })
                            .catch((error) => {
                                setTimeout(() => {
                                    Auth.currentSession()
                                        .then((session) => { 
                                            validateSession(session.idToken.payload)
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            dispatch(setUserStatus('error'))
                                        });
                                }, 3000);//second wait
                            });
                    }, 1000);
                }
            })
    }
}