import { Mui } from "@osu/react-ui";
import { Link } from "react-router-dom";
import awardRequestConstants from "../util/awardRequestConstants";
import { objIsEmpty } from "../util/functions";
import { renderCell } from "../util/table-helpers";

const { fieldNames } = awardRequestConstants;

function searchByName(query = '', list = [], fields = null) {
  if(!query || !list) {
    return list || []
  }
  
  let searchFields = Array.isArray(fields) ? fields : ['name']
  const lowercasedQuery = query.toLowerCase()
  let newList = list.filter(item => {
    const someSearchFieldMatches = searchFields.some(field => {
      let itemValue = item[field] || ''

      if(!objIsEmpty(itemValue)) {
        let str = ''
        Object.keys(itemValue).forEach(item => {
          str += ((itemValue[item] || "") + " ")
        })
        itemValue = str.trim()
      } 

      if(itemValue) {
        itemValue = itemValue.toString()
        itemValue = itemValue.toLowerCase()
      }
      
      return lowercasedQuery.includes(itemValue) || itemValue.includes(lowercasedQuery)
    })

    return someSearchFieldMatches
  })
  return newList
}

const CustomSemesterHeader = (props = {}) => {
  return (
    <Mui.Typography
      className="display-flex flex-direction-column"
      component="p"
      variant="subtitle2"
      color="inherit"
    >
      {props.children}
      <Mui.Typography component="span" variant="body2" color="inherit">
          Funding Status
      </Mui.Typography>
    </Mui.Typography>
  );
};

function createColumns(semesters = []) {
  let typeSafeSemesters = Array.isArray(semesters) ? semesters : [];

  let cols = [
    {
      field: fieldNames.name,
      headerName: "Student Name",
      flex: 0.5,
      sortComparator: (v1, v2, param1, param2) => {
        return param1.value?.name?.localeCompare(param2.value?.name);
      },
      renderCell: (props) => {
        const { awardId, emplid, name } = props?.value || {};
        if (awardId && emplid) {
          return (
            <Mui.Link
              component={Link}
              to={`/students-on-award/${awardId}/${emplid}`}
            >
              {name}
            </Mui.Link>
          );
        }
        return name;
      },
    },
    { field: fieldNames.emplid, headerName: "EMPLID", flex: 0.5 },
    { field: fieldNames.title, headerName: "Award", flex: 0.5, renderCell: renderCell.award, sortComparator: (v1, v2, param1, param2) => {
      return param1.value?.name?.localeCompare(param2.value?.name);
    }, },
  ];

  typeSafeSemesters.forEach(({ semester, label }) => {
    cols.push({
      field: semester,
      headerName: <CustomSemesterHeader>{label}</CustomSemesterHeader>,
      flex: .75,
    });
  });

  return cols;
}

function formatStudentWithSemesters(list = []) {
    let newList = []
    let pending = []
    list.forEach((item = {}, index) => {
        const { semesters = [], awardId = '', emplid = '', campusId = '' } = item
        let containsPending
        let semesterProps = {}
        let id = `${index}#`
        if(awardId) id += (awardId + '#')
        if(campusId) id += (campusId + '#')
        if(emplid) id += (emplid)
        semesters.forEach(semester => {
            if(semester?.semester) {
                semesterProps[semester.semester] = semester?.status
                if(semester?.status === 'PENDING') {
                  containsPending = true
                }
            }
        })
        const newItem = {
          id,
          [fieldNames.name]: {
            name: item.name,
            awardId, 
            emplid
          },
          [fieldNames.emplid]: item.emplid,
          [fieldNames.additionalPending]: item.additionalPending,
          [fieldNames.title]: {
            name: item.title,
            id: awardId,
            prefix: `/students-on-award/`
          },
          ...semesterProps
        }
        newList.push(newItem)
        if(containsPending) {
          pending.push(newItem)
        }
    })

    return {
      list: newList,
      pending
    }
}

const createFilters = (semesters = [], rows) => {
  if(!semesters || semesters.length === 0) {
    return []
  }
  let filters = [], initialFilterState = {}
  semesters.forEach((semester, index) => {
    const semesterFilterId = semester?.semester + '-filter' + index
    let options = [{
      value: '',
      label: '',
      id: semesterFilterId + '-noneoption'
    }]
    if(rows && rows.length > 0) {
      rows.forEach((row, index) => {
        const rowSemester = row[semester?.semester]
        if(!!rowSemester && !options.some(opt => opt?.value === rowSemester)) {
          options.push({
            value: rowSemester,
            label: rowSemester,
            id: semesterFilterId + index + rowSemester
          })
        }

      })
    }

    initialFilterState[semester?.semester] = ''
    filters.push({
      label: semester?.label,
      id: semesterFilterId,
      name: semester?.semester,
      options
    })
  })

  return {
    filters,
    initialFilterState
  }
}

export { createColumns, formatStudentWithSemesters, searchByName, createFilters };
