import { GET_STUDENT_AWARD_INFO, SET_TEMP_STUDENT_AWARD_INFO, SUBMIT_STUDENT, CACHED_SUBMIT_STUDENT } from "../actions-index";

export function addStudent(state = {}, action = { payload: {} }) {
    let tempDirectory = {}
    const studentId = action?.payload?.emplid || action?.payload?.campusId
    const metrics = action?.payload && action?.payload?.metrics
    if (studentId && Object.keys(metrics || {}).length > 0) {
        tempDirectory = {
            TEMP: {}
        }
        if (action?.payload?.emplid) {
            tempDirectory.TEMP[action?.payload?.emplid] = action?.payload?.metrics
        }
        if (action?.payload?.campusId) {
            tempDirectory.TEMP[action?.payload?.campusId] = action?.payload?.metrics
        }
    }

    switch (action.type) {
        case GET_STUDENT_AWARD_INFO:
            return {
                ...state,
                ...tempDirectory,
                searchResults: {
                    status: 'success',
                    ...action.payload
                }
            }
        case SET_TEMP_STUDENT_AWARD_INFO:
            return {
                ...state,
                ...tempDirectory
            }
        case SUBMIT_STUDENT:
            return {
                ...state,
                submitStudentResult: {
                    status: 'success',
                    ...action.payload
                }
            }
        case CACHED_SUBMIT_STUDENT:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    cachedSubmit: action.payload
                }
            }
        default:
            return state
    }
}