import { isValid } from "date-fns";
import { formStatuses } from "../enums";
import { objIsEmpty, removeUndefined } from "../util/functions";

export const initialState = {
  awardId: { value: "" },
  awardStatus: { value: "NEW" },
  academicProgram: { value: "" },
  file: { value: { fileName: "", documentId: "" } },
  college: { value: "ASC" },
  directive: { value: "" },
  fundingAgency: { value: "" },
  grantFundProposalDeadline: { value: "" },
  numberOfStudents: { value: "" },
  percentFundingRequest: { value: "" },
  principalInvestigator: { value: {} },
  projectTitle: { value: "" },
  selectedCategory: { value: "" },
  selectedSemesters: { value: [] },
  stipendFunded: { value: "" },
  requester: {value: ""},
  comments: { value: "" },
  fundingType: { value: "" }
};

export function formsReducer(state, action) {
  switch (action.type) {
    case "RESET": {
      return initialState;
    }
    case "REPLACE": {
      let newState = Object.assign({}, initialState);
      const { payload = {} } = action;
      newState.awardId.value = payload.id || "";
      newState.awardStatus.value = payload.status || "";
      newState.academicProgram.value = payload.program || "";
      newState.file.value = {
        fileName: payload.documentation?.fileName || "",
        documentId: payload.documentation?.documentId || "",
      };
      newState.college.value = payload.college || "";
      newState.directive.value = payload.documentation?.directive || "";
      newState.fundingAgency.value =
        payload.sponsorFunding?.fundingAgency || "";
      newState.grantFundProposalDeadline.value =
        payload.sponsorFunding?.deadline || "";
      newState.numberOfStudents.value =
        payload.studentFunding?.numberOfStudents || "";
      newState.percentFundingRequest.value =
        payload.studentFunding?.fundingPercent || "";
      newState.principalInvestigator.value =
        payload.contactInformation?.principalInvestigator || {};
      newState.projectTitle.value = payload.name || "";
      newState.selectedCategory.value = payload.category || "";
      newState.selectedSemesters.value =
        payload.studentFunding?.semesters || [];
      newState.stipendFunded.value =
        payload.sponsorFunding?.monthlyStipend || "";
      newState.requester.value =
        payload.contactInformation?.requester || "";
      newState.comments.value = payload.comments || "";
      newState.fundingType.value = payload?.fundingInformation?.fundingType || "";

      newState.file.label = "File Upload";
      newState.directive.label = "Directive";
      newState.fundingAgency.label = "Funding Agency";
      newState.selectedSemesters.label = "Semesters";
      newState.grantFundProposalDeadline.label = "Proposal Deadline";
      newState.numberOfStudents.label = "Number of Students";
      newState.percentFundingRequest.label = "Percent Funding Request";
      newState.principalInvestigator.label =
        "Principal Investigator or Advisor";
      newState.projectTitle.label = "Project Title";
      newState.selectedCategory.label = "Category";
      newState.stipendFunded.label = "Stipend Funded";
      newState.fundingType.label = "Funding Type";
      
      return newState;
    }
    case "ERROR": {
      const name = action.name;
      let newState = Object.assign({}, state);
      newState[name] = {
        value: state[name].value,
        dirty: true,
        error: action.error,
      };

      return newState;
    }
    default: {
      const name = action.target.name;
      let value;

      switch (action.target.type) {
        case "checkbox":
          value = action.target.checked;
          break;
        default:
          value = action.target.value;

          break;
      }

      let newState = Object.assign({}, state);

      newState[name] = {
        value,
        dirty: true,
        label: state[name] && state[name].label,
        ignore: state[name] && state[name].ignore,
      };

      return newState;
    }
  }
}

const requiredFields = {
  id: {
    label: 'ID',
    type: 'string'
  },
  award: {
    properties: {
      category: {
        label: 'Award - Category',
        type: 'string'
      },
      name: {
        label: 'Award - Name',
        type: 'string'
      }
    }
  },
  contactInformation: {
    label: "Contact Information",
    type: "array"
  },
  sponsorFunding: {
    properties: {
      deadline: {
        label: `Sponsor Funding Deadline Date`,
        type: 'date'
      },
      fundingAgency: {
        label: `Sponsor Funding - Agency`,
        type: 'string'
      },
      monthlyStipend: {
        label: `Sponsor Funding - Monthly Stipend`,
        type: ['string', 'number']
      }
    }
  },
  studentFunding: {
    properties: {
      fundingPercent: {
        label: `Student Funding - Percent Funding`,
        type: ['number']
      },
      numberOfStudents: {
        label: `Student Funding - Number of Students`,
        type: ['number']
      },
      semesters: {
        label: `Student Funding - Semesters`,
        type: 'array'
      }
    }
  }
}

export const errorTypes = {
  unexpectedType: 'unexpectedType',
  missingValue: 'missingValue'
}

const comparator = (value, key) => {
  let error = ''
  if (!value) {
    error = errorTypes.missingValue
  } else if (key === 'date') {
    let validatedDate = isValid(value) || isValid(new Date(value))
    error = !validatedDate ? errorTypes.unexpectedType : ''
  } else if (key === 'array') {
    error = !Array.isArray(value) ? errorTypes.unexpectedType : value.length === 0 ? errorTypes.missingValue : ''
  } else if (Array.isArray(key)) {
    error = !(key.includes(typeof value)) ? errorTypes.unexpectedType : ''
  } else {
    error = typeof value !== key ? errorTypes.unexpectedType : ''
  }
  return error
}

export const iterateAndValidateAgainstKeys = (obj = {}, fields = requiredFields, parentKey = '') => {
  let errors = []

  Object.keys(fields).forEach(key => {
    const properties = fields[key].properties
    const value = obj[key]

    if (!objIsEmpty(properties)) {
      let nestedErrors = iterateAndValidateAgainstKeys(value, properties, key)
      errors = errors.concat(nestedErrors)
    } else {
      const error = comparator(value, fields[key]?.type)
      if (error) {
        let newErr = {
          key,
          error,
          label: fields[key]?.label
        }
        if (parentKey) newErr.parentKey = parentKey

        errors.push(newErr)
      }
    }

  })
  return errors
}

export const createAwardRequest = (state, options = {}) => {
  let changedState = {};
  for (const stateItem in state) {
    if (state[stateItem].dirty === true) {
      changedState[stateItem] = state[stateItem];
    }
  }

  let award = {
    category: changedState.selectedCategory?.value,
    name: changedState.projectTitle?.value
  }
  award = removeUndefined(award);

  let contactInformation = [];
  if (changedState.principalInvestigator?.value) {
    contactInformation.push({
      type: "principalInvestigator",
      name: changedState.principalInvestigator?.value?.name,
      email: changedState.principalInvestigator?.value?.email,
      emplid: changedState.principalInvestigator?.value?.emplid
    });
  }
  if (changedState.requester?.value) {
    contactInformation.push({
      type: "requester",
      name: changedState.requester?.value?.name,
      email: changedState.requester?.value?.email,
      emplid: changedState.requester?.value?.emplid
    });
  }
  contactInformation = removeUndefined(contactInformation);

  let documentation = {
    directive: changedState.directive?.value,
    fileName: changedState.file?.value?.fileName
  }

  if (changedState.file?.value?.s3Object) {
    documentation.s3Object = changedState.file?.value?.s3Object
  } else if (changedState.file?.value?.documentId) {
    documentation.documentId = changedState.file?.value?.documentId
  }
  documentation = removeUndefined(documentation);

  let sponsorFunding = {
    monthlyStipend: changedState.stipendFunded?.value,
    fundingAgency: changedState.fundingAgency?.value
  }
  sponsorFunding = removeUndefined(sponsorFunding);
  if (changedState.grantFundProposalDeadline?.value) {
    sponsorFunding.deadline = changedState.grantFundProposalDeadline?.value
  }

  const hasValueProp = (obj) => obj && Object.prototype.hasOwnProperty.call(obj, 'value')
  let semesters = [];

  let studentFunding = {}
  let changedStateStudentFunding = {}
  if (hasValueProp(changedState.percentFundingRequest)) {
    changedStateStudentFunding.fundingPercent = changedState.percentFundingRequest.value || 0
  }
  if (hasValueProp(changedState.numberOfStudents)) {
    changedStateStudentFunding.numberOfStudents = changedState.numberOfStudents.value || 0
  }
  if (hasValueProp(changedState.selectedSemesters)) {
    for (let semester of changedState.selectedSemesters.value) {
      semesters.push(semester.semester);
    }
    if (semesters.length > 0) {
      changedStateStudentFunding.semesters = semesters
    }
  }
  const existingSemesters = Array.isArray(options?.studentFunding?.semesters)
    ? options?.studentFunding?.semesters.map(({ semester }) => semester)
    : []
  if (!objIsEmpty(changedStateStudentFunding)) {
    studentFunding = Object.assign(
      {
        fundingPercent: options?.studentFunding?.fundingPercent,
        numberOfStudents: options?.studentFunding?.numberOfStudents,
        semesters: existingSemesters
      },
      changedStateStudentFunding
    )

    studentFunding.fundingPercent = parseInt(studentFunding.fundingPercent)
    if (!studentFunding.fundingPercent || isNaN(studentFunding.fundingPercent)) {
      studentFunding.fundingPercent = 0
    }

    studentFunding.numberOfStudents = parseInt(studentFunding.numberOfStudents)
    if (!studentFunding.numberOfStudents || isNaN(studentFunding.numberOfStudents)) {
      studentFunding.numberOfStudents = 0
    }
  }

  let comment = {
    comment: changedState.newComment?.value,
    emplid: changedState.emplid?.value
  }
  comment = removeUndefined(comment);

  let request = {
    id: state.awardId?.value,
    award,
    contactInformation,
    documentation,
    comment,
    sponsorFunding,
    studentFunding
  };

  request = removeUndefined(request);

  return request;
}

export const checkReadOnly = (formStatus, isGradSchool) => {
  let isEditable = [
    formStatuses.new, formStatuses.saved, formStatuses.cancelled, formStatuses.denied
  ].includes(formStatus)

  if (isGradSchool)
    isEditable = true;

  return !isEditable
} 