import React from "react";
import PropTypes from "prop-types";
import Main from '../../Main/components'
import { MAIN_ID } from "../../util/constants";
import { withOSUTheming, useScrollToHash, Mui, withTableOfContents } from '@osu/react-ui'
import { AppProvider } from "../context";
import OSUNavBar from "react-osu-navbar";
import Navbar from "../../Navbar/components";
import withAuthentication from "../../Authentication/withAuthentication";
import Footer from "../../Footer/components";
import useCssVariableUpdater from "../hooks/useCssVariableUpdater";

function App(props = {}) {
  const { navHeight } = props
  const { ref } = useScrollToHash()
  const trigger = Mui.useScrollTrigger({
    disableHysteresis: true,
    threshold: navHeight
  });

  return  <div className="App" ref={ref}>
  <OSUNavBar />
  <Navbar />
  <Main spacingTop={!trigger} className="anchor-link" id={MAIN_ID} />
  <Footer />
</div>
}

const TOCWrappedApp = withTableOfContents(App)

function Provider(props = {}) {
  const { authorized, handleSignIn, authStatus, handleSignOut  } = props;
  const { navHeight } = useCssVariableUpdater()
  const appContext = {
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
    navHeight
  }

  
  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp navHeight={navHeight} />
    </AppProvider>
  );
}

export default withOSUTheming(withAuthentication(Provider))