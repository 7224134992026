import {CREATE_TEMP_AWARD_REQUESTS, GET_ACTIVE_REQUESTS, GET_ALL_AWARD_REQUESTS, CLEAR_TEMP_AWARD_REQUESTS} from "../actions-index";
import { constructAwardRequestFilters } from "./transform";

export function allAwardRequests(state = {}, action = {}) {
    const { payload = {} } = action
    const { list, status, typeOfList, clear, ...rest } = payload || {}

    switch(action.type) {
        case GET_ALL_AWARD_REQUESTS:
            return {
                ...state,
                list,
                typeOfList,
                filters: Array.isArray(list) ? constructAwardRequestFilters(list) : {},
                status: Array.isArray(list) ? 'success' : payload.status
            }
        case GET_ACTIVE_REQUESTS: {
            return {
                ...state,
                active: {
                    list,
                    filters: Array.isArray(list) ? constructAwardRequestFilters(list) : {},
                    status: Array.isArray(list) ? 'success' : payload.status
                }
            }
        }
        case CREATE_TEMP_AWARD_REQUESTS:
            return {
                ...state,
                TEMP: {
                    ...state.TEMP || {},
                    ...rest || {},
                }
            }
        case CLEAR_TEMP_AWARD_REQUESTS:
            return {
                ...state,
                TEMP: clear ? {} : state.TEMP
            }
        default:
            return state
    }
}