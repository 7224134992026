import { Mui, sortBySortPriority } from "@osu/react-ui"
import { Link } from "react-router-dom"
import { WF_LABELS } from "../util/constants"
import { renderCell, valueFormatter } from "../util/table-helpers"

function formatHistoryFields(awardId = '', emplid = '', data = {}){
    const { name, metrics = {}, award = {} } = data
    const semesters = award?.semesters || []
    let formatted = {
        name,
        emplid,
        award: {
            id: awardId,
            name: award?.name || '',
            program: award?.program || ''
        },
        semesters,
        plans: metrics?.plans,
        gpa: metrics?.cumGpa,
        creditHours: metrics?.earnedUnits,
        standing: metrics?.standing || 'Good'
    }

    let rows = []
    if(Array.isArray(semesters)) {
        semesters.forEach((semester = {}, index) => {
            const { semester: sem = '', label, academicYear = '', status = '' } = semester
            rows.push({
                id: `${sem}#${academicYear}-${index}`,
                semester: <Mui.Link role={undefined} component={Link} to={`/students-on-award/${awardId}/${emplid}?semester=${sem}`}>
                    {label}
                </Mui.Link>,
                academicYear,
                status,
                semesterWithoutLink: semester
            })
        })

        rows = rows.sort(function(a, b) {
            if(a.id < b.id) {
                return -1;
            }
            else 
                return 1;
        });
    }

    const table = {
        caption: 'Student Semester History',
        columns: [
            {
                field: 'semester',
                headerName: "Semester"
            },
            {
                field: 'academicYear',
                headerName: "Academic Year"
            },
            {
                field: 'status',
                headerName: "Status"
            }
        ],
        rows
    }

    formatted.table = table

    return formatted
}

function formatSemesterDetailFields(data = {}) {
    const { metrics, name = '', award = { appointmentType: '', label: '' } } = data
    let comments = Array.isArray(data?.award?.comments) ? data?.award?.comments : []
    let workflowHistory = Array.isArray(award?.workflows) ? award?.workflows : []
    
    let formatted = {
        name,
        award: {
            id: award?.id,
            academicYear: award?.academicYear
        },
        appointmentType: award?.appointmentType,
        semesterLabel: award?.label
    }
    
    let commentTable = {
        caption: 'Comments',
        id: 'comments-table',
        sortPriority: 0,
        columns: [
            {
                field: 'name',
                headerName: "Name",
                width: 30
            },
            {
                field: 'emplid',
                headerName: "EMPLID",
                width: 20
            },
            {
                field: 'date',
                headerName: "Date",
                width: 20
            },
            {
                field: 'comment',
                width: 30,
                headerName: "Comment"
            }
        ],
        rows: []
    }
    comments.forEach((cmt, idx) => {
        const { name = '', emplid = '', date = '', comment = '' } = cmt
        commentTable.rows.push({
            id: `${emplid}${date}#${idx}`,
            name, 
            emplid, 
            date: valueFormatter.date({ value: date }), 
            comment
        })
    })
 
    let wfColumns =[
        {
            field: 'name',
            width: 30,
            headerName: "Name"
        },
        {
            field: 'email',
            width: 20,
            headerName: "E-mail"
        },
        {
            field: 'action',
            width: 20,
            headerName: "Status"
        },
        {
            field: 'actionDate',
            width: 30,
            headerName: "Date Completed"
        }
    ]

    const convertHistory = (history = [], idx1) => {
        let rows = []
        if(!Array.isArray(history)) {
            return rows
        }
        history.forEach((wfh, idx) => {
            const { name, email, action, actionDate } = wfh
            rows.push({
                id: `${email}${action}${actionDate}#${idx1}#${idx}`,
                name,
                email: email && renderCell.email({ value: email }),
                action, 
                actionDate: valueFormatter.date({ value: actionDate })
            })
        })
        return rows
    }

    let wfTables = []
    workflowHistory.forEach((wf, index) => {
        let wfTable = {
            title: workflowHistory.length > 1 && `Workflow ${index + 1}`,
            id: `worflow_table#${index}`,
            tables: []
        }
        const wfKeys = Object.keys(wf)

        wfKeys.forEach((key, idx) => {
            if(Array.isArray(wf[key]) && wf[key].length > 0) {
                let rows = convertHistory(wf[key], idx)
               wfTables.push({
                   id: wfTable.id + `nestedtable-${idx}`,
                   caption: WF_LABELS[key] || '',
                   rows,
                   sortPriority: key === 'currentApprovers' ? index + 1 : index + 2,
                   columns: wfColumns
               })
            }

        })
    })

    let tables = sortBySortPriority([commentTable, ...wfTables])

    return {
        ...formatted,
        workflows: award?.workflows,
        tables,
        metrics
    }
}

export {
    formatHistoryFields,
    formatSemesterDetailFields
}