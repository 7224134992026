import { API } from "aws-amplify";
import { GET_PENDING } from "../actions-index";
import * as queries from "../graphql/queries";
import { generateAction } from "../redux-helpers";
import { formatTasks } from "./transform";

export function getPendingTasks() {
    return async (dispatch) => {
      dispatch(generateAction(GET_PENDING, { status: "loading" }));
  
      try {
        var response = await API.graphql({
          query: queries.getPendingTasks
        });
        if(Array.isArray(response?.data?.getPendingTasks)) {
          dispatch(generateAction(GET_PENDING, { list: formatTasks(response?.data?.getPendingTasks) }))
        } else {
          throw new Error('Unexpected response from pending tasks')
        }
      } catch (err) {
        console.log("graphql error: ", err);
        dispatch(generateAction(GET_PENDING, { status: 'ERROR' }))
      }
    };
  }
  