import { API } from "aws-amplify";
import { generateAction } from "../redux-helpers";
import {
  ACTIVE_STUDENTS,
  AVAILABLE_SEMESTERS,
  PENDING_COUNT,
  STUDENTS_WITHOUT_FUNDING,
  STUDENT_SEMESTER_TABLES,
} from "../actions-index";
import * as queries from "../graphql/queries";
import { formatStudentWithSemesters } from "./transform";

export function setStudentSemesterTables({ status }) {
    return (dispatch) => {
      dispatch(generateAction(STUDENT_SEMESTER_TABLES, { status }));
    };
}  

export function setStudentsWithoutFunding({ list }) {
  return (dispatch) => {
    dispatch(generateAction(STUDENTS_WITHOUT_FUNDING, formatStudentWithSemesters(list)));
  };
}

export function setActiveStudents({ list }) {
  return (dispatch) => {
    const { pending = [], list: formattedList = [] } = formatStudentWithSemesters(list)
    dispatch(generateAction(ACTIVE_STUDENTS, { pending, list: formattedList }));
    dispatch(generateAction(PENDING_COUNT, pending.length));
  };
}

export function setAvailableSemesters({ list }) {
  return (dispatch) => {
    dispatch(generateAction(AVAILABLE_SEMESTERS, { list }));
  };
}

export function getStudentsWithSemesters() {
    return async (dispatch) => {
      dispatch(setStudentSemesterTables({ status: "loading" }))
  
      try {
        var response = await API.graphql({
          query: queries.getStudentsWithSemesters
        });
        if(!response?.data?.getDashboard || Object.keys(response?.data?.getDashboard).length === 0) {
          throw new Error('Unable to get getStudentsWithSemesters / dashboard')
        }
        const activeStudents = response?.data?.getDashboard?.activeStudents || []
        const studentsWithoutFunding = response?.data?.getDashboard?.studentsWithoutFunding || []

        dispatch(setAvailableSemesters({ list: response?.data?.getDashboard?.businessProcess?.availableSemesters }))
        dispatch(setActiveStudents({ list: activeStudents }))
        dispatch(setStudentsWithoutFunding({ list: studentsWithoutFunding }))
      } catch (err) {
        console.log("graphql error: ", err);
        dispatch(setStudentSemesterTables({ status: "ERROR" }))
      }
    };
  }