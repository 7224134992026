import filters from "../ViewRequests/constants/filters"

export function omitReduxProps(props = {}) {
    const clonedObject = Object.assign({}, props)
    delete clonedObject.history
    delete clonedObject.location
    delete clonedObject.match
    delete clonedObject.staticContext
    delete clonedObject.dispatch

    return clonedObject
}

export function isUnauthenticated(error) {
    const status = (error?.response && error?.response?.status) ? error.response.status : null;
    const unauthorizedErrors = ["The user is not authenticated", "No current user", "Request failed with status code 401"];
    
    return unauthorizedErrors.includes(error) || status === 401
}

export function filterRowsByState (state = {}, rows = []) {
    let filteredRows = rows
    const colKeys = Object.keys(state)
    if(colKeys.length > 0) {
      colKeys.forEach(key => {
        let filterValue = state[key]
        
        if(filterValue) {
          filteredRows = filteredRows.filter(row => {
            let rowVal = row[key]
            if(key === filters.range.name) {
              const { from, to } = JSON.parse(filterValue) || {}
              const inRange = (rowVal >= from) && (to === 1 ? (rowVal <= to) : (rowVal < to))
              if(rowVal === 0) {
                return from === 0 && to === 0
              }
              return inRange
            }
            return rowVal === filterValue
          })
        }
      })
    }
  
    return filteredRows
  }
  
export const getMetricsFromState = (state = {}, emplid = '') => {
  const tempDirectory = state?.addStudent && state?.addStudent?.TEMP ? state.addStudent.TEMP : {}
  const metrics = emplid && Object.keys(tempDirectory || {}).length > 0 && tempDirectory[emplid]

  return metrics
}
  
export function objIsEmpty(obj) {
  if (!obj)
      return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

//TODO: move to utils
export function removeUndefined(obj) {
  for (let sobj in obj) {
      if (obj[sobj] === undefined || objIsEmpty(obj[sobj]) || obj[sobj].length === 0) {
          delete obj[sobj];
          continue;
      }
  }

  return obj;
}

export function intersect(a, b) {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set([...setA].filter(x => setB.has(x)));
  return Array.from(intersection);
}
