import { GET_AWARD, ADD_COMMENT } from "../actions-index";

export function studentsOnAward(state = {}, action = {}) {
    switch (action.type) {        
        case GET_AWARD:
            return {
                ...state,
                award: {
                    status: 'success',
                    ...action.payload
                }
            }
        case ADD_COMMENT:
            return {
                ...state,
                comment: {
                    status: 'success',
                    ...action.payload
                }
            }
        default:
            return state
    }
}