import { Auth } from 'aws-amplify';
import { authenticationFlowPaths } from '../Routes/paths';

export function redirectToLogIn(redirectPath = ''){
    const { oauth } = Auth.configure();
    let path = redirectPath
    
    if(!!window.location.pathname && !path) {
        path = window.location.pathname
        if(window.location.search) {
            path += window.location.search
        }
    }
    
    const splitPaths = path.split('?')
    if(splitPaths[0]) {
        const notRedirectablePaths = authenticationFlowPaths.map(({ path }) => path)
        if(notRedirectablePaths.includes(splitPaths[0])) {
            path = '/'
        }
    }
    
    Auth.federatedSignIn({
        provider: oauth.idp,
        customState: (path || '/')
    });
}