import awardRequestConstants from "../util/awardRequestConstants";

const { fieldNames } = awardRequestConstants

function formatAwardsRequests(list = []) {
  let transformedList = [];
  if (Array.isArray(list)) {
    list.forEach((request = {}, index) => {
        const { name, fundingAgency, fundingRemaining, total, available, program, principleInvestigator, deadline, college, decisionDate, id, status } = request

        transformedList.push({
            id: `${id}#${index}`,
            [fieldNames.title]: {
              name,
              id
            },
            [fieldNames.fundingRemaining]: fundingRemaining,
            [fieldNames.sponsor]: fundingAgency,
            [fieldNames.program]: program,
            [fieldNames.principalInvestigator]: principleInvestigator,
            [fieldNames.proposalDeadline]: deadline,
            [fieldNames.decisionDate]: decisionDate,
            [fieldNames.status]: status,
            [fieldNames.college]: college,
            [fieldNames.total]: total,
            [fieldNames.available]: available,
        })
    });
  }
  return transformedList
}

const constructAwardRequestFilters = (list = []) => {
  if(!Array.isArray(list)) {
    return []
  }
  let programs = []
  let colleges = []
  
  const checkAndAdd = (arr, field, listItem, listItemIndex) => {
    const relatedField = listItem[field]
    
    if(relatedField && !arr.some(el => el.value === relatedField)) {
      arr.push({
        value: relatedField,
        label: relatedField,
        id: `${relatedField}-${listItemIndex}`
      })
    }
  }
  

  list.forEach((item, listIndex) => {
    checkAndAdd(programs, 'program', item, listIndex)
    checkAndAdd(colleges, 'college', item, listIndex)
  })

  return {
    programs,
    colleges
  }
}

export { formatAwardsRequests, constructAwardRequestFilters };
