import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { SEARCH_PI_ADVISOR, UPDATE_AWARD, SUBMIT_AWARD, GET_FILE, ADD_COMMENT, GET_AVAILABLE_SEMESTERS, GET_AWARD, CLEAR_FORM_DATA, FILE_PROGRESS, UPLOAD_FILE, COMPLETE_TASK, CANCEL_WORKFLOW, DELETE_REQUEST, CACHED_SUBMIT_AWARD, CACHED_UPDATE_AWARD, CACHED_CANCEL_WORKFLOW, CACHED_COMPLETE_TASK, SUBMIT_AWARD_ERRORS } from "../actions-index";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import axios from 'axios';
import { clearAward } from '../AwardRequest/actions';
import { iterateAndValidateAgainstKeys } from './utilities';
import { intersect } from '../util/functions';


export function setProgress(progress) {
    return async (dispatch) => {
        dispatch(generateAction(FILE_PROGRESS, progress));
    }
}
export function searchPIAdvisor(lastname, options = {}) {
    const { includeAwards, includeMetrics } = Object.assign({ includeAwards: false, includeMetrics: false }, options)
    return async (dispatch) => {
        dispatch(generateAction(SEARCH_PI_ADVISOR, { status: "loading" }));
        try {
            let data
            var response = await API.graphql({ query: queries.getPersonInfo, variables: { includeAwards, includeMetrics, studentId: lastname } });

            const affiliations = response?.data?.getPersonInfo?.affiliations
            const allowed = ['staff@osu.edu', 'faculty@osu.edu']
            if (Array.isArray(affiliations)) {
                const isStaffFaculty = affiliations.length > 0 && (intersect(affiliations, allowed).length > 0)
                if (response?.data?.getPersonInfo?.emplid && isStaffFaculty) {
                    data = [
                        {
                            name: response?.data?.getPersonInfo?.name,
                            email: response?.data?.getPersonInfo?.email,
                            campusId: response?.data?.getPersonInfo?.campusId,
                            emplid: response?.data?.getPersonInfo?.emplid
                        }
                    ]
                }
            }
            if (data) {
                dispatch(generateAction(SEARCH_PI_ADVISOR, {
                    data
                }))
            } else {
                throw new Error('Unable to get related staff / faculty')
            }
        }
        catch (err) {
            console.error("Error retrieving pi advisor: ", err);
            dispatch(generateAction(SEARCH_PI_ADVISOR, { status: "error" }));
        }
    }
}

export function updateAward(values = {}) {
    return async (dispatch) => {
        try {
            dispatch(generateAction(UPDATE_AWARD, { id: values.id, status: "loading" }));
            var response = await API.graphql({ query: mutations.updateAward, variables: { ...values } });
            let responseWithId = response.data.updateAward
            if(!responseWithId.id) {
                responseWithId.id = values.id
            }
            if(responseWithId.id) {
                dispatch(generateAction(UPDATE_AWARD, responseWithId));
                dispatch(getAward(responseWithId.id))
            } else {
                throw new Error('Unexpected response from update award')
            }
        }
        catch (err) {
            console.error("Error updating award: ", err);
            dispatch(generateAction(CACHED_UPDATE_AWARD, values));
            dispatch(generateAction(UPDATE_AWARD, { status: "error" }));
        }
    }
}
export function submitAward(awardValues = {}) {
    let values = Object.assign({}, awardValues)
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_AWARD, { id: values.id, status: "loading" }));
        try {
            let errors = iterateAndValidateAgainstKeys(values)
            if(values?.documentation?.documentId) {
                delete values.documentation.documentId
            } else if (!values?.documentation?.s3Object) {
                errors.push({
                    key: 's3Object',
                    error: true,
                    label: `Please make sure you have uploaded a document.`
                })
            }
            if(errors.length > 0) {
                dispatch(generateAction(SUBMIT_AWARD_ERRORS, { errors }));
                throw new Error("Somethings wrong with the values!")
            }
            var response = await API.graphql({ query: mutations.submitAward, variables: { ...values } });
            if(response?.data?.submitAward?.id) {
                dispatch(generateAction(SUBMIT_AWARD, response.data.submitAward));
                dispatch(getAward(response.data.submitAward.id))
            } else {
                throw new Error('Unexpected response from submit award')
            }
        }
        catch (err) {
            console.error("Error submitting award: ", err);
            dispatch(generateAction(CACHED_SUBMIT_AWARD, values));
            dispatch(generateAction(SUBMIT_AWARD, { status: "error", code: err?.errors?.[0]?.message }));
        }
    }
}

export function completeTask(values = {}) {
    return async (dispatch) => {
        dispatch(generateAction(COMPLETE_TASK, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.completeTask, variables: { ...values } });
            dispatch(generateAction(COMPLETE_TASK, { ...response.data.completeTask, success: true }));
            if(values.awardId) 
                dispatch(getAward(values.awardId));                
        }
        catch (err) {
            console.error("Error completing action: ", err);
            dispatch(generateAction(CACHED_COMPLETE_TASK, values));
            dispatch(generateAction(COMPLETE_TASK, { status: "error" }));
        }
    }
}

export function deleteAward(id) {
    return async (dispatch) => {
        dispatch(generateAction(DELETE_REQUEST, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.deleteAward, variables: { id } });
            dispatch(generateAction(DELETE_REQUEST, {...response.data.deleteAward, status: "success"}));
        }
        catch (err) {
            console.error("Error deleting award: ", err);
            dispatch(generateAction(DELETE_REQUEST, { status: "error" }));
        }
    }
}

export function cancelWorkflow(id, awardId) {
    return async (dispatch) => {
        dispatch(generateAction(CANCEL_WORKFLOW, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.cancelWorkflow, variables: { id: id } });
            if(response?.data?.cancelWorkflow?.success) {
                dispatch(generateAction(CANCEL_WORKFLOW, response.data.cancelWorkflow));
                if(awardId) {
                    dispatch(getAward(awardId))
                }
            } else {
                throw new Error('Unexpected response from cancel workflow')
            }
        }
        catch (err) {
            console.error("Error cancelling workflow: ", err);
            dispatch(generateAction(CANCEL_WORKFLOW, { status: "error" }));
            dispatch(generateAction(CACHED_CANCEL_WORKFLOW, {
                workflowId: id,
                awardId
            }));
        }
    }
}

export function getAvailableSemesters(years) {
    return async (dispatch) => {
        dispatch(generateAction(GET_AVAILABLE_SEMESTERS, { status: "loading" }));
        try {
            var response = await API.graphql({ query: queries.getAvailableSemesters, variables: { numberOfYears: years } });
            dispatch(generateAction(GET_AVAILABLE_SEMESTERS, response.data.getAvailableSemesters))
        }
        catch (err) {
            console.error("Error retrieving semesters: ", err);
            dispatch(generateAction(GET_AVAILABLE_SEMESTERS, { status: "error" }));
        }
    }
}

export function getAward(id) {
    return async (dispatch) => {
        try {
            dispatch(generateAction(GET_AWARD, { id, status: "loading" }));
            if(!id) {
                throw Error('No id passed to get award')
            }
            var response = await API.graphql({ query: queries.getAward, variables: { id: id } });
            if(response?.data?.getAward?.contactInformation?.principalInvestigator) {
                dispatch(generateAction(SEARCH_PI_ADVISOR, {
                    data: [response?.data?.getAward?.contactInformation?.principalInvestigator]
                }))
            }
            
            dispatch(generateAction(GET_AWARD, response.data.getAward))
        }
        catch (err) {
            console.error(`Error retrieving award ${id}: `, err);
            dispatch(generateAction(GET_AWARD, { status: "error" }));
        }
    }
}

export function getFile(documentId, fileName) {
    return async (dispatch) => {
        dispatch(generateAction(GET_FILE, { status: "loading" }));
        try {
            var response = await API.graphql({ query: queries.getFile, variables: { documentId, fileName } });
            dispatch(generateAction(GET_FILE, response.data.getFile))
        }
        catch (err) {
            console.error(`Error retrieving file ${documentId}: `, err);
            dispatch(generateAction(GET_FILE, { status: "error" }));
        }
    }
}

export function uploadFile(file) {
    return async (dispatch) => {
        dispatch(generateAction(UPLOAD_FILE, { status: "loading" }));
        try {
            //get signed url
            dispatch(generateAction(FILE_PROGRESS, 10));
            let signedUrl = await API.graphql({ query: queries.getSignedUrl, variables: { id: file.name } });
            signedUrl = signedUrl.data.getSignedUrl;
            dispatch(generateAction(FILE_PROGRESS, 40));
            let uploadResp = await axios.put(signedUrl.signedUrl, file, { headers: { 'Content-Type': "application/pdf" } });
            dispatch(generateAction(FILE_PROGRESS, 75));
            dispatch(generateAction(UPLOAD_FILE, { status: "success", fileName: file.name, s3Object: signedUrl.s3Object }));
            dispatch(generateAction(FILE_PROGRESS, 100));
        }
        catch (err) {
            console.error(`Error retrieving signed urls: `, err);
            dispatch(generateAction(UPLOAD_FILE, { status: "error" }));
        }
    }
}

export function addComment(id, comment) {
    return async (dispatch) => {
        dispatch(generateAction(ADD_COMMENT, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.addCommentAward, variables: { id: id, comment: comment } });
            dispatch(generateAction(ADD_COMMENT, { ...response.data.addComment, status: "success" }));
            dispatch(getAward(id))
        }
        catch (err) {
            console.error("Error adding comment: ", err);
            dispatch(generateAction(ADD_COMMENT, { status: "error" }));
        }
    }
}

export function clearFormData() {
    return dispatch => {
        dispatch(clearAward())
        dispatch(generateAction(CLEAR_FORM_DATA, { clear: true }));
    }
}

