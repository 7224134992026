import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { ADD_COMMENT } from "../actions-index";
import * as mutations from '../graphql/mutations';

export function addCommentStudent(id, comment, semester, studentId) {
    return async (dispatch) => {
        dispatch(generateAction(ADD_COMMENT, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.addCommentStudent, variables: { id, comment: comment, semester, studentId  } });
            console.log("graphql response: ", response);
            dispatch(generateAction(ADD_COMMENT, { ...response.data.addComment, status: "success" }));

        }
        catch (err) {
            console.error("Error adding comment: ", err);
            dispatch(generateAction(ADD_COMMENT, { status: "error" }));
        }
    }
}