const fieldNames = {
  title: "title",
  sponsor: "sponsor",
  program: "program",
  principalInvestigator: "principalInvestigator",
  proposalDeadline: "deadline",
  decisionDate: "decisionDate",
  status: "status",
  college: "college",
  id: 'id',
  fundingRemaining: 'fundingRemaining',
  total: 'total',
  available: 'available',
  name: 'name',
  emplid: 'emplid',
  additionalPending: 'additionalPending'
};

const awardRequestConstants = {
  fieldNames
};

export default awardRequestConstants;
