import { API } from "aws-amplify";
import { GET_STUDENT_AWARD_HISTORY, GET_STUDENT_AWARD_SEMESTER_DETAIL } from "../actions-index";
import { setTempMetrics } from "../AddStudent/actions";
import * as queries from "../graphql/queries";
import { generateAction } from "../redux-helpers";
import { getMetricsFromState } from "../util/functions";
import { formatHistoryFields, formatSemesterDetailFields } from "./transform";

export function getStudentHistory(awardId, emplid) {
  return async (dispatch, getState = {}) => {
    const state = Object.assign({ addStudent: { TEMP: {} }  }, getState())
    const metrics = getMetricsFromState(state, emplid)
    const includeMetrics = !metrics

    dispatch(generateAction(GET_STUDENT_AWARD_HISTORY, { status: "loading" }));
    try {
      var response = await API.graphql({
        query: queries.getStudentAwardDetails,
        variables: {
          awardId: awardId,
          includeMetrics,
          studentId: emplid,
        },
      });
      let getStudentAwardDetails = response?.data?.getStudentAwardDetails || {}
      if (
        getStudentAwardDetails &&
        Object.keys(getStudentAwardDetails).length > 0
      ) {
        if(!includeMetrics) {
          getStudentAwardDetails.metrics = metrics
        } else {
          dispatch(setTempMetrics({ 
            emplid: getStudentAwardDetails?.emplid, 
            campusId: getStudentAwardDetails?.campusId, 
            metrics: getStudentAwardDetails?.metrics || {}
          }))
        }
        dispatch(
          generateAction(
            GET_STUDENT_AWARD_HISTORY,
            {
              awardId,
              emplid,
              history: formatHistoryFields(awardId, emplid, getStudentAwardDetails)
            }
          )
        );
      } else {
        throw new Error("Unable to get getStudentAwardDetails");
      }
    } catch (err) {
      console.error("Error getting student info: ", err);
      dispatch(generateAction(GET_STUDENT_AWARD_HISTORY, { status: "error" }));
    }
  };
}

export function getStudentSemesterDetail(awardId, semester, emplid) {
  return async (dispatch, getState) => {
    const state = Object.assign({ addStudent: { TEMP: {} }  }, getState())
    dispatch(generateAction(GET_STUDENT_AWARD_SEMESTER_DETAIL, { status: "loading" }));
    const metrics = getMetricsFromState(state, emplid)
    const includeMetrics = !metrics

    try {
      var response = await API.graphql({
        query: queries.getStudentSemesterAwardDetail,
        variables: {
          awardId: awardId,
          semester: semester,
          studentId: emplid,
          includeMetrics
        },
      });
      const { getStudentSemesterAwardDetail } = response?.data || {}
      if (
        getStudentSemesterAwardDetail &&
        Object.keys(getStudentSemesterAwardDetail).length > 0
      ) {
        if(!includeMetrics) {
          getStudentSemesterAwardDetail.metrics = metrics
        } else {
          dispatch(setTempMetrics({ 
            emplid, 
            campusId: getStudentSemesterAwardDetail?.campusId, 
            metrics: getStudentSemesterAwardDetail?.metrics || {}
          }))
        }

        dispatch(generateAction(GET_STUDENT_AWARD_SEMESTER_DETAIL, { 
          awardId, 
          semester, 
          emplid,
          semesterDetail: formatSemesterDetailFields(getStudentSemesterAwardDetail)
         }));
      } else {
        throw new Error("Unable to get getStudentSemesterAwardDetail");
      }
    } catch (err) {
      console.error("Error getting student info: ", err);
      dispatch(generateAction(GET_STUDENT_AWARD_SEMESTER_DETAIL, { status: "error" }));
    }
  };
}