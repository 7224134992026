import { Mui } from '@osu/react-ui'
import React from 'react'

function Email (props = {}) {
    const { mailto, ...rest } = props
    return <Mui.Link href={`mailto:${mailto}`} {...rest}/>
}

Email.defaultProps = {
    mailto: 'grad-schoolfellowships@osu.edu',
    children: 'Graduate School'
}

export default Email