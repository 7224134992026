import { STATUSES } from "../../util/constants";

let statusOptions = [];

Object.keys(STATUSES.labels).forEach((statusField, index) => {
  statusOptions.push({
    value: statusField,
    label: STATUSES.labels[statusField],
    id: `${statusField}-${index}`,
  });
});

const status = {
  label: "Status",
  id: `status`,
  name: `status`,
  options: statusOptions,
};

const program = {
  label: "Program",
  id: `program`,
  name: `program`,
};

const college = {
  label: "College",
  id: `college`,
  name: `college`,
};

const range = {
  label: "Funding Remaining",
  id: `fundingRemaining`,
  name: `fundingRemaining`,
  options: [
    {
      value: null,
      label: ``,
      id: `fundingremaining-null`
    },
    {
      value: JSON.stringify({
        from: 0,
        to: 0,
      }),
      label: `No Funding Remaining`,
      id: `fundingremaining-00000`
    },
    {
      value: JSON.stringify({
        from: 0,
        to: .2,
      }),
      label: `Less than 20%`,
      id: `fundingremaining-0-20`
    },
    {
      value: JSON.stringify({
        from: .2,
        to: .5,
      }),
      label: `Between 20% and 50%`,
      id: `fundingremaining-20-50`
    },
    {
      value: JSON.stringify({
        from: .5,
        to: 1,
      }),
      label: `Between 50% and 100%`,
      id: `fundingremaining-50-100`
    }
  ],
};

const filters = {
  status,
  program,
  college,
  range
};

export default filters;
