import { addMonths } from "date-fns"

const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true"
const OSU_NAV_ID = 'osu_navbar'
const MAIN_ID = 'page-content'
const ACCESSIBLE_REFLOW_HEIGHT = `399px`
const TOC_WIDTH = `15rem`

const statusFields = {
    CANCELLED: "CANCELLED",
    EXPIRED: "EXPIRED",
    SAVED: "SAVED",
    PENDING: "PENDING",
    DENIED: "DENIED",
    PREAPPROVED: "PREAPPROVED",
    PENDING_FUNDING_INFORMATION: "PENDING_FUNDING_INFORMATION",
    APPROVED: "APPROVED",
    DENIED_FUNDING_INFORMATION: "DENIED_FUNDING_INFORMATION",
}

const statusLabels ={
    [statusFields.SAVED]: "Saved Not Submitted",
    [statusFields.PENDING]: "Pending Approval",
    [statusFields.CANCELLED]: "Cancelled",
    [statusFields.EXPIRED]: "Expired",
    [statusFields.DENIED]: "Denied",
    [statusFields.PENDING_FUNDING_INFORMATION]: "Pending Grad School Funding Verification",
    [statusFields.DENIED_FUNDING_INFORMATION]: "Denied Funding Information",
    [statusFields.PREAPPROVED]: "Pending Funding Information (Pre-Approved)",
    [statusFields.APPROVED]: "Approved",
}

const WF_LABELS = {
    currentApprovers: 'Current Approvers',
    history: 'History'
}

const STATUSES = {
    fields: statusFields,
    labels: statusLabels
}

const AWARD_EXPIRES_BY = addMonths(new Date(), 12)

export {
    FORCED_AUTHENTICATION,
    OSU_NAV_ID,
    MAIN_ID,
    ACCESSIBLE_REFLOW_HEIGHT,
    TOC_WIDTH,
    STATUSES,
    WF_LABELS,
    AWARD_EXPIRES_BY
}
