import awardRequestConstants from "../util/awardRequestConstants";
import { STATUSES } from "../util/constants";

const { fieldNames } = awardRequestConstants;

function formatTasks(list = []) {
  let transformedList = [];
  if (Array.isArray(list)) {
    list.forEach((request = {}, index) => {
      const {
        name,
        fundingAgency,
        program,
        principleInvestigator,
        deadline,
        college,
        decisionDate,
        id,
        status,
      } = request;

      if (status !== STATUSES.fields.APPROVED) {
        transformedList.push({
          id,
          [fieldNames.title]: {
            name,
            id,
          },
          [fieldNames.sponsor]: fundingAgency,
          [fieldNames.program]: program,
          [fieldNames.principalInvestigator]: principleInvestigator,
          [fieldNames.proposalDeadline]: deadline,
          [fieldNames.decisionDate]: decisionDate,
          [fieldNames.status]: status,
          [fieldNames.college]: college,
        });
      }
    });
  }
  return transformedList;
}

export { formatTasks };
