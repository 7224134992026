export const createAward = `mutation CreateAward($college: String!, $name: String!, $program: String!, $category: String!) {
  createAward(input: {college: $college, name: $name, program: $program, category: $category}) {
    id
    status
  }
}
`;


export const updateAward = `mutation UpdateAward($id: String!, $award: AwardInput, $contactInformation: [ContactInformationInput], $documentation: DocumentationInput, $sponsorFunding: SponsorFundingFormInput, $studentFunding: StudentFundingSemesetersInput, $comment: CommentInput ) {
  updateAward(id: $id, input: {award: $award, contactInformation: $contactInformation, documentation: $documentation, sponsorFunding: $sponsorFunding, studentFunding: $studentFunding, comment: $comment}) {
    category
    college
    id
    name
    program
  }
}`

export const cancelWorkflow = `mutation CancelWorkflow($id: String!) {
  cancelWorkflow(id: $id)  {
    success
  }
}`

export const submitAward = `mutation SubmitAward($id: String!, $award: AwardInput, $contactInformation: [ContactInformationInput], $documentation: DocumentationInput, $sponsorFunding: SponsorFundingFormInput, $studentFunding: StudentFundingSemesetersInput, $comment: CommentInput ) {
  submitAward(id: $id, input: {award: $award, contactInformation: $contactInformation, documentation: $documentation, sponsorFunding: $sponsorFunding, studentFunding: $studentFunding, comment: $comment}) {
    id
    workflowId
  }
}`

export const completeTask = `mutation CompleteTask($id: String!, $action: String!, $fundingType: String) {
  completeTask(id: $id, action: $action, input: {fundingType: $fundingType}) {
    success
  }
}`

export const submitFundingVerification = `mutation SubmitFundingVerification($id: String!, $eligible: EligibleWorkdayAward, $ineligible: IneligibleWorkdayAward) {
  submitFundingVerification(id: $id, input: {eligible: $eligible, ineligible: $ineligible}) {
    id
  }
}`

export const submitStudentAward = `mutation SubmitStudentAward($id: String!, $appointmentType: String!, $emplid: String!, $semesters: [String!]) {
  submitStudentAward(id: $id, input: {appointmentType: $appointmentType, emplid: $emplid, semesters: $semesters}) {
    id
    workflowId
  }
}`

export const deleteAward = `mutation DeleteAward($id: String!) {
  deleteAward(id: $id) {
    success
  }
}`

export const addCommentAward = `mutation AddComment($id: String!, $comment: String!) {
  addComment(id: $id, input: { comment: $comment }) {
    success
  }
}`

export const addCommentStudent = `mutation AddComment($id: String!, $comment: String!, $semester: String!, $studentId: String!) {
  addComment(id: $id, input: { comment: $comment }, student: {semester: $semester, studentId: $studentId}) {
    success
  }
}`