import { combineReducers } from 'redux';
import { authentication } from './Authentication/reducers'
import { forms } from './Forms/reducers';
import { awardRequest } from './AwardRequest/reducers';
import { workflowDetail } from './WorkflowDetail/reducers';
import { fundingVerification } from './FundingVerification/reducers';
import { addStudent } from './AddStudent/reducers';
import { findStudent } from './FindStudent/reducers';
import { allAwardRequests } from './ViewRequests/reducers';
import { dashboard } from './MainDashboard/reducers';
import { pendingTasks } from './PendingTasks/reducers';
import { studentSemesterTables } from './StudentSemesterTables/reducers';
import { studentHistory } from './StudentHistory/reducers';
import { studentsOnAward } from './StudentsOnAward/reducers';


export default combineReducers({
    authentication,
    forms,
    awardRequest,
    workflowDetail,
    fundingVerification,
    addStudent,
    findStudent,
    allAwardRequests,
    dashboard,
    pendingTasks,
    studentSemesterTables,
    studentHistory,
    studentsOnAward
});