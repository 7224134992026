import { Mui } from "@osu/react-ui";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const dateVF = ({ value }) => {
  if (value) {
    const datifiedDate = new Date(value);
    if (datifiedDate.toString() !== "Invalid Date") {
      return format(datifiedDate, "MM/dd/yyyy");
    }
  }
};

const emailRC = ({ value }) => {
  if (value && value.includes("@")) {
    return <Mui.Link href={`mailto:${value}`}>{value}</Mui.Link>;
  }
  return value
};

const AwardRC = ({ value }) => {
  const { id, name, prefix } = value || {};
  if(!id && !name) {
    return value
  }
  if (id) {
    return (
      <Mui.Tooltip placement="right" title={`ID: ${id}`}>
        <Mui.Link component={Link} to={`${prefix}${id}`}>
          {name}
        </Mui.Link>
      </Mui.Tooltip>
    );
  }
  return name;
}

const valueFormatter = {
  date: dateVF,
};

const renderCell = {
  email: emailRC,
  award: AwardRC,
};

export { valueFormatter, renderCell };
