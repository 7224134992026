export const awardFields = `{
    total
    status
    program
    principleInvestigator
    name
    monthlyStipend
    id
    fundingRemaining
    fundingAgency
    monthlyStipend
    deadline
    decisionDate
    college
    available
    upcomingSemesters {
        label
        semester
    }
}`;

const semesters = `
    semesters {
        academicYear
        label
        semester
        status
    }
`

export const activeStudents = `{
    ${semesters}
    name
    emplid
    awardId
    title
    campusId
    additionalPending
}`;

export const studentFunding = `{
    ${semesters}
    name
    emplid
    campusId
    awardId
    title
}`;

export const metrics = `
    metrics {
        standing
        plans
        earnedUnits
        cumGpa
    }
`