import { GET_PENDING } from "../actions-index";

export function pendingTasks(state = {}, action = {}) {
  const { payload } = action;
  
  switch (action.type) {
    case GET_PENDING:
      return {
        ...state,
        list: payload?.list,
        status: payload?.status ? payload.status : payload?.list ? 'success' : undefined
      }
    default:
      return state;
  }
}
