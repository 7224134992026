import { GET_DASHBOARD, PENDING_COUNT } from "../actions-index";

export function dashboard(state = {}, action = {}) {
  const { payload } = action;
  let dash = Object.assign({}, state);
  dash.status = payload?.status;
  switch (action.type) {
    case GET_DASHBOARD:
      if (payload?.upcomingDeadline || payload?.dashboardAwards || payload?.fundingRemaining) {
        dash.dashboardAwards = payload.dashboardAwards;
        dash.upcomingDeadline = payload.upcomingDeadline;
        dash.fundingRemaining = payload.fundingRemaining;
        dash.studentCount = {
          ...dash.studentCount || {},
          ...payload?.studentCount
        };
        dash.status = "success";
      }
      return dash;
    case PENDING_COUNT: {
      return {
        ...dash,
        studentCount: {
          ...dash.studentCount || {},
          pending: action.payload
        }
      }
    }
    default:
      return state;
  }
}
