import { CACHED_SUBMIT_FUNDING_VERIFICATION, SUBMIT_FUNDING_VERIFICATION } from "../actions-index";

export function fundingVerification(state = {}, action = {}) {
    switch (action.type) {
        case SUBMIT_FUNDING_VERIFICATION:
            return {
                ...state,
                fundingVerificationResult: {
                    status: action?.payload?.id ? 'success' : action?.payload?.status,
                },
                cachedFundingVerification: action?.payload?.id ? null : state?.cachedFundingVerification
            }
        case CACHED_SUBMIT_FUNDING_VERIFICATION:
            return {
                ...state,
                cachedFundingVerification: action?.payload
            }
        default:
            return state
    }
}