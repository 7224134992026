import React from 'react';
import { Mui } from '@osu/react-ui';
import AuthButton from '../containers/AuthButton';
import { Link, useLocation } from 'react-router-dom';

const useStyles = Mui.makeStyles((theme) => {
    return {
        appBar: {
          top: 0,
          zIndex: theme.zIndex.appBar
        },
        root: {
          flexGrow: 1,
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        title: {
          flexGrow: 1,
        },
      }
});

const navMenuOptions = [
  {
    to: '/',
    children: 'Grad Fee Match'
  },
  {
    to: '/requests/',
    children: 'Award Requests'
  },
  {
    to: '/FindStudent/',
    children: 'Find Student'
  },
  {
    to: '/requests-active/',
    children: 'Active Awards'
  },
  {
    to: '/student-requests/all',
    children: 'Active Students'
  }
]

const slashEnding = (path) => typeof path === 'string' ? (path.endsWith('/') ? path : `${path}/`) : null

export default function Navbar(props = {}) {
  const classes = useStyles();
  const location = useLocation()

  const navProps = (to, index) => {
    const lastItem = (navMenuOptions.length - 1) === index
    let properties = {
      className: lastItem ? `margin-right-auto` : `margin-right-2`,
      color: 'secondary'
    }
    const currentLocation = slashEnding(location?.pathname)
    const displayPath = slashEnding(to?.pathname || to)
    
    const rootPathParsed = currentLocation.split('/')[1]
    const displayPathParsed = displayPath.split('/')[1]
    
    const current = (displayPathParsed) && (rootPathParsed === displayPathParsed)
    if(displayPath === '/' ? currentLocation === '/' : current) {
      properties['aria-current'] = 'page'
      properties.color = 'primary'
    }

    return properties
  }

  return (
    <Mui.AppBar id="grad-fee-header"  {...props} color="inherit" className={classes.appBar} position="sticky">
      <Mui.Toolbar className="">
          {navMenuOptions.map(({ to, children }, index) => <Mui.Button {...navProps(to, index)} role={undefined} component={Link} to={to} >
            {children}
          </Mui.Button>)}
        <AuthButton />
      </Mui.Toolbar>
    </Mui.AppBar>
  );
}