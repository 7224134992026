import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { GET_WORKFLOW_DETAIL } from "../actions-index";
import * as queries from '../graphql/queries';

export function getWorkflow(id) {
    return async (dispatch) => {
        dispatch(generateAction(GET_WORKFLOW_DETAIL, { status: "loading" }));
        try {
            var response = await API.graphql({ query: queries.getWorkflowByAward, variables: { id: id } });
            dispatch(generateAction(GET_WORKFLOW_DETAIL, response.data.getAward))
        }
        catch (err) {
            console.error("Error updating award: ", err);
            dispatch(generateAction(GET_WORKFLOW_DETAIL, { status: "error" }));
        }
    }
}