import { lazy } from 'react';
import LogIn from '../Authentication/components/LogIn';
import LogOut from '../Authentication/components/LogOut';
import Unauthorized from './views/Unauthorized';

const WebAndMobileDocs = lazy(() => import('../WebAndMobileDocumentation/components'));
const Forms = lazy(() => import('../Forms/containers'));
const AwardRequest = lazy(() => import('../AwardRequest/containers'));
const ViewRequests = lazy(() => import('../ViewRequests/containers'));
const MainDashboard = lazy(() => import('../MainDashboard/containers'));
const FindStudent = lazy(() => import('../FindStudent/containers'));
const StudentsOnAward = lazy(() => import('../StudentsOnAward/containers'));
const WorkflowDetail = lazy(() => import('../WorkflowDetail/containers'));
const PendingTasks = lazy(() => import('../PendingTasks/containers'));
const StudentSemesterTables = lazy(() => import('../StudentSemesterTables/containers'));
const FundingVerification = lazy(() => import('../FundingVerification/containers'));
const AddStudent = lazy(() => import('../AddStudent/containers'));

const PATHS = [
    {
        title: "Award Request",
        path: "/forms/:id?",
        Component: Forms
    },
    {
        title: "Fee Match Award Request",
        path: "/AwardRequest",
        Component: AwardRequest
    },
    {
        title: "Award Fee Match Requests",
        path: "/requests/:status?",
        Component: ViewRequests
    },
    {
        title: "Active Award Fee Match Requests",
        path: "/requests-active",
        Component: ViewRequests,
        props: {
            showActiveOnly: true
        }
    },
    {
        title: "Graduate Matching Tuition and Fee Authorization",
        path: "/",
        Component: MainDashboard
    },
    {
        title: "Find Student On Award",
        path: "/FindStudent",
        Component: FindStudent
    },
    {
        title: "Students on Award",
        path: "/students-on-award/:awardId/:emplid?",
        Component: StudentsOnAward
    },
    {
        title: "Workflow Detail",
        path: "/workflow/:id?",
        Component: WorkflowDetail
    },
    {
        title: "",
        path: "/FundingVerification",
        Component: FundingVerification
    },
    {
        title: "Add Student",
        path: "/AddStudent",
        Component: AddStudent
    },
    {
        title: "Pending Tasks",
        path: "/pending-tasks",
        Component: PendingTasks
    },
    {
        title: 'Active Student Award Requests',
        path: "/student-requests/:type?",
        Component: StudentSemesterTables
    }
];

const authenticationFlowPaths = [
    {
        path: '/login',
        Component: LogIn 
    },
    {
        path: '/logout',
        Component: LogOut 
    },
    {
        path: '/unauthorized',
        Component: Unauthorized 
    }
]

export {
    authenticationFlowPaths,
    PATHS as default
}