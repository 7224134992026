import { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import { OpenInNew } from "@material-ui/icons";

export const categories = [
    {
        key: "Matching Tuition and Fee Auth Program",
        helperText: <Fragment>
            For more information on this program please visit{" "}<Mui.Link target="_blank" rel="noopener" href="https://gradsch.osu.edu/matching-tuition">
                Matching Tuition{" "}
                <OpenInNew fontSize="inherit" />
            </Mui.Link>
        </Fragment>,
        value: "matching_tuition"
    },
    {
        key: "Fee Match Cost Share",
        helperText: <Fragment>
            Discretionary and limited funds
        </Fragment>,
        value: "cost_share"
    }
];

export const formStatuses = {
    new: "NEW",
    saved: "SAVED",
    pending: "PENDING",
    preapproved: "PREAPPROVED",
    denied: "DENIED",
    pendingFundingInformation: "PENDING_FUNDING_INFORMATION",
    deniedFundingInformation: "DENIED_FUNDING_INFORMATION",
    approved: "APPROVED",
    expired: "EXPIRED",
    cancelled: "CANCELLED"
};

export const fundingTypes = [
    "NSF – NRT",
    "T32",
    "T34",
    "T15",
    "R25",
    "K award",
    "F32",
    "F30",
    "F31",
    "F33",
    "MSTP",
    "Other Dual Degree",
    "Cost Share R01",
    "Cost Share P01",
    "Cost Share Center Grants",
    "FLAS",
    "Pelotonia Fellow",
    "Discovery Fellow",
    "Fulbright",
    "University-Foreign Government Contract",
    "Other Federal Agency Grant",
    "Other State Agency Grant",
    "Industry (external for profit)",
    "NASA Finest",
    "Private Foundation"
].sort((a,b) => {return a > b ?  1 : b > a ?  -1 : 0}); //alphanumeric sort

export const appointmentTypes = [
    {
        type: 'GRA - GS Match',
        helperText: "Student on GRA active appointment"
    },
    {
        type: 'N/A - External Fellowship',
        helperText: "Student not on active appointment"
    },
    {
        type: 'Grad Trainee',
        helperText: "Student on Grad Trainee appointment"
    },
    {
        type: 'Graduate Fellow',
        helperText: "Student on Grad Fellow active appointment"
    }
]

let appointmentTypeOptions = [
    {
        label: '',
        id: `no-appt`,
        value: ''
    }
]

appointmentTypes.forEach((type, index) => {
    let stripped = type.type.replace(/[^A-Za-z-]/g, "")
    stripped = stripped.toLowerCase()

    appointmentTypeOptions.push({
        label: type.type,
        id: `${stripped}-${index}`,
        value: type.type,
        helperText: type.helperText
    })
})

export {
    appointmentTypeOptions
}