
import Amplify, { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import {GET_ACAD_PROGRAMS, CREATE_AWARD, CLEAR_AWARD} from "../actions-index";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export function getAcademicPrograms() {
    return async (dispatch) => {
        dispatch(generateAction(GET_ACAD_PROGRAMS, {status: "loading"}));

        try {
            var response = await API.graphql( { query: queries.getAcademicPrograms });
        }
        catch(err) {
            console.log("graphql error: ", err);
        }


        dispatch(generateAction(GET_ACAD_PROGRAMS, response))
    }
}


export function createAward(values = {}) {
    return async (dispatch) => {
        dispatch(generateAction(CREATE_AWARD, { status: "loading" }));
        try {
            var response = await API.graphql({ query: mutations.createAward, variables: {...values}} );
            dispatch(generateAction(CREATE_AWARD, response))
        }
        catch (err) {
            console.error("Error creating award: ", err);
            dispatch(generateAction(CREATE_AWARD, { status: "error" }));
        }
    }
}

export function clearAward() {
    return dispatch => {
      dispatch(generateAction(CLEAR_AWARD, { clear: true }));
    }
  }

